@import '../../themes/vars.less';

.brand {
  z-index: 1;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 14px;

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;

    > .name {
      :local {
        animation: fadeRightIn 300ms @ease-in-out;
        animation-fill-mode: both;
      }
    }

    img {
      width: 36px;
    }
  }
}

.header {
  padding: 0;
  box-shadow: @shadow-2;
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 60px;
  z-index: 9;
  align-items: center;
  background-color: #fff;

  &.ant-progress-success-bg,
  .ant-progress-bg {
    background-color: #04a9e3 !important;
  }

  &.collapsed {
    .brand {
      padding-right: 24px;
    }
  }

  &.fixed {
    position: fixed;
    top: 0;
    right: 0;
    width: ~'calc(100%)';
    z-index: 29;
    transition: width 0.2s;

    &.collapsed {
      width: ~'calc(100%)';
    }
  }

  :global {
    .ant-menu-submenu-title {
      height: 60px;
    }

    .ant-menu-horizontal {
      line-height: 60px;

      & > .ant-menu-submenu:hover {
        color: @primary-color;
        background-color: @hover-color;
      }
    }

    .ant-menu {
      border-bottom: none;
      height: 60px;
    }

    .ant-menu-horizontal > .ant-menu-submenu {
      top: 0;
      margin-top: 0;
    }

    .ant-menu-horizontal > .ant-menu-item,
    .ant-menu-horizontal > .ant-menu-submenu {
      border-bottom: none;
    }

    .ant-menu-horizontal > .ant-menu-item-active,
    .ant-menu-horizontal > .ant-menu-item-open,
    .ant-menu-horizontal > .ant-menu-item-selected,
    .ant-menu-horizontal > .ant-menu-item:hover,
    .ant-menu-horizontal > .ant-menu-submenu-active,
    .ant-menu-horizontal > .ant-menu-submenu-open,
    .ant-menu-horizontal > .ant-menu-submenu-selected,
    .ant-menu-horizontal > .ant-menu-submenu:hover {
      border-bottom: none;
    }
  }

  .leftContainer {
    display: flex;
    align-items: center;
  }

  .rightContainer {
    display: flex;
    align-items: center;
  }

  .button {
    color: @blue-6;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    font-size: 18px;
    cursor: pointer;
    transition: @transition-ease-in;

    &:hover {
      color: @primary-color;
      background-color: @hover-color;
    }
  }
}

.iconButton {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  cursor: pointer;
  .background-hover();

  &:hover {
    .iconFont {
      color: @primary-color;
    }
  }

  & + .iconButton {
    margin-left: 8px;
  }

  .iconFont {
    color: #6d6c6c;
    font-size: 20px;
  }
}

.notification {
  padding: 24px 0;
  width: 320px;
  .notificationItem {
    transition: all 0.3s;
    padding: 12px 24px;
    cursor: pointer;
    &:hover {
      background-color: @hover-color;
    }
  }
  .clearButton {
    text-align: center;
    height: 48px;
    line-height: 48px;
    cursor: pointer;
    .background-hover();
  }
}

.notificationPopover {
  :global {
    .ant-popover-inner-content {
      padding: 0;
    }
    .ant-popover-arrow {
      display: none;
    }
    .ant-list-item-content {
      flex: 0;
      margin-left: 16px;
    }
  }
}

@media (max-width: 767px) {
  .header {
    width: 100% !important;
  }
}

@white: #fff;@black: #000;@primary-color: #04a9e3;@primary-color-2: #04a9e3;@primary-color-accent: #1890ff;@border-color: #adafb2;@border-color-dark: #9b9b9b;@chart-table-border-color: #00000026;@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC',
  'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial,
  sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@font-size-base: 14px;@font-size-lg: @font-size-base + 2px;@font-size-sm: 14px;@alem-dark-blue: #0f2549;@layout-header-background: @alem-dark-blue;@layout-sider-background: @alem-dark-blue;@layout-trigger-background: #002140;@layout-trigger-color: #fff;@card-head-background: #f4f3f3;@heading-color: fade(#000, 85%);@heading-color-dark: fade(#fff, 100%);@icon-color: inherit;@icon-color-hover: fade(@black, 75%);@text-color: #666;@text-color-secondary: fade(@black, 45%);@text-color-inverse: @white;@text-color-dark: fade(@white, 85%);@text-color-secondary-dark: fade(@white, 65%);@text-selection-bg: @primary-color;@border-radius-base: 3px;@border-radius-sm: 2px;@shadow-color: rgba(0, 0, 0, 0.05);@shadow-1-down: 4px 4px 40px @shadow-color;@border-color-split: #f4f4f4;@border-color-base: #e5e5e5;@hover-color: #f9f9fc;@header-height: 60px;