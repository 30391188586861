@sm: 576px;
@md: 768px;
@lg: 992px;
@xl: 1200px;
@xxl: 1600px;

@white: #fff;
@black: #000;

@primary-color: #15a9e1;

@min-vh-100: 100vh;
@h-100: 100%;

@font-family-helvetica-neue: 'Helvetica Neue', sans-serif;
@font-family-roboto: Roboto, sans-serif;

.media (@width) {
  @media only screen and (max-width: @width) {
    .mixin() {
      @arguments();
    }
  }
}

.text(@font-weight: initial, @font-size: initial, @line-height: initial, @color: #606060) {
  font-family: 'Noto Sans', sans-serif;
  font-style: normal;
  font-weight: @font-weight;
  font-size: @font-size;
  line-height: @line-height;
  color: @color;
}

.home-login {
  height: 100vh !important;
  .home-login-right-col-logo {
    background: url('../../../static/images/alem_health_logo.svg') no-repeat
      center;
    width: 150px;
    height: 30px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 0 0;
  }
  .home-login-right-col {
    .home-login-right-col-content {
      padding: 24px !important;
      box-sizing: content-box;
      padding: 24px 24px 24px;
      width: 305px;
      margin: auto;
      .qr-scanned-button {
        width: 100%;
        margin-top: 38px;

        &.qr-scanned-button--no-margin {
          margin: 0;
        }
      }

      .home-login-right-col-body {
        .qr-scanned-faild {
          background: #ff00000a;
        }

        .qr-scanned-faild,
        .qr-scanned-success {
          padding: 30px;
          border: 1px solid #e8e8e8;
          border-radius: 4px;
          margin-top: 24px;
          text-align: center;

          .home-login-check-circle {
            display: block;
            margin-bottom: 10px;
          }

          .home-login-check-circle-img {
            height: 60px;
            margin-bottom: 20px;
          }
        }

        .qr-scanned-success .home-login-check-circle {
          color: @primary-color;
        }

        .qr-scanned-faild .home-login-check-circle {
          color: red;
        }
      }

      .home-login-right-col-content-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
      }

      .home-login-right-col-title {
        .text(bold, 24px, 36px);
        margin: 40px 0 0;

        .media(@md) {
          text-align: left;
        }
      }

      .home-login-right-col-paragraph {
        .text(normal, 13px, 24px);
        margin: 14px 0 26px;

        .media(@md) {
          text-align: left;
        }
      }

      .home-login-right-col-login-btn {
        // .btn();
        background: @primary-color;
        color: #fff;
      }

      .home-login-right-col-login-divider {
        padding: 0 24px;

        .ant-divider {
          background-color: unset;
          margin: 20px 0;

          .ant-divider-inner-text {
            .text(500, 12px, 28px, #c4c4c4);
            font-family: Roboto, sans-serif;
          }
        }
      }

      .home-login-right-col-create-account-btn {
        // .btn();
        border: 1px solid #b9b9b9;
      }

      .home-login-right-col-links {
        margin-top: 24px;

        a {
          .text(600, 12px, 28px, @primary-color);
          text-decoration: underline;
        }
      }
    }
  }
  &.qr-scanned {
    max-width: 420px;
    width: 100%;
    margin: auto !important;
  }

  .home-login-main-col {
    background-color: #f2f2f2;
    height: 100%;

    .home-login-main-col-background-img {
      background: url('../../../static/images/map-logo.svg') no-repeat center;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: 'center';
      width: 100%;
      max-width: 800px;
      height: 500px;
      margin: 100px auto 0;
    }

    .media(@md) {
      display: none;
    }
  }
}

@white: #fff;@black: #000;@primary-color: #04a9e3;@primary-color-2: #04a9e3;@primary-color-accent: #1890ff;@border-color: #adafb2;@border-color-dark: #9b9b9b;@chart-table-border-color: #00000026;@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC',
  'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial,
  sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@font-size-base: 14px;@font-size-lg: @font-size-base + 2px;@font-size-sm: 14px;@alem-dark-blue: #0f2549;@layout-header-background: @alem-dark-blue;@layout-sider-background: @alem-dark-blue;@layout-trigger-background: #002140;@layout-trigger-color: #fff;@card-head-background: #f4f3f3;@heading-color: fade(#000, 85%);@heading-color-dark: fade(#fff, 100%);@icon-color: inherit;@icon-color-hover: fade(@black, 75%);@text-color: #666;@text-color-secondary: fade(@black, 45%);@text-color-inverse: @white;@text-color-dark: fade(@white, 85%);@text-color-secondary-dark: fade(@white, 65%);@text-selection-bg: @primary-color;@border-radius-base: 3px;@border-radius-sm: 2px;@shadow-color: rgba(0, 0, 0, 0.05);@shadow-1-down: 4px 4px 40px @shadow-color;@border-color-split: #f4f4f4;@border-color-base: #e5e5e5;@hover-color: #f9f9fc;@header-height: 60px;