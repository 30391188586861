.ant-modal-body {
  position: relative;
}

.signature-modal {
  .ant-modal-header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 22;
    background: white;
  }

  .ant-modal-body {
    padding: 0px;
  }

  .ant-modal-close {
    position: fixed;
    top: 0px;
    right: 24px;
    z-index: 3333;
  }
}

.signature-form {
  .signature-header {
    position: -webkit-sticky;
    position: sticky;
    top: 55px;
    z-index: 22;
    background: white;
    padding: 24px 24px 10px 24px;

    .header-wrapper {
      .preview {
        .ant-card-body {
          display: flex;
          justify-content: center;
        }

        .empty-container {
          width: 260px;
          text-align: center;
          min-height: 180px;
          align-content: center;
        }

      }

      .header-separator {
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
        height: 1px;
        margin: 16px 0px 0px 0px;
      }
    }
  }

  .signature-body {
    padding: 0px 24px;
  }

  .ant-form-item {
    margin-bottom: 0px;

    .ant-form-item-control {
      line-height: unset;
    }
  }

  .label {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    line-height: 22px;
    margin-bottom: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .asterisk-text {
      color: #F5222D;
    }
  }

  .signature-mode {
    margin-bottom: 16px;

    &-radio {
      display: flex;
    }

    .ant-radio-button-wrapper {
      width: 100%;
      text-align: center;
    }
  }

  .digitally-signed-switch {
    margin-bottom: 16px;
  }

  .font-field {
    margin-bottom: 16px;
  }

  .upload-btn {
    margin-bottom: 16px;

    .upload-file-item {
      display: flex;
      align-items: center;
    }

    .filename {
      font-size: 14px;
      line-height: 22px;
      color: #15A9E1;
      width: 350px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 10px;
    }

    &-input {
      width: 100%;

      .ant-upload {
        width: 100%;
      }
    }

    &-text {
      font-size: 14px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.45);
    }
  }

  .optional-fields {
    // .label {
    //   margin-bottom: 10px !important;
    // }

    .optional-field-box {
      border: 0.5px dashed;
      border-color: rgba(0, 0, 0, 0.15);
      padding: 12px;
      margin-bottom: 8px;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 600;

      .reporting-date-text {
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.45);
        margin-top: 5px;
      }

      .ant-switch {
        margin-right: 10px;
      }

      .reporting-date-radio {
        width: 100%;
        display: flex;

        .ant-radio-button-wrapper {
          width: 100%;
          text-align: center;
        }
      }
    }
  }

  .error-text {
    font-size: 14px;
    line-height: 22px;
    color: #FF4D4F;
  }

  .signature-footer {
    margin-top: 10px;
    position: relative;
    height: 200px;

    .encryption-section {
      border: 1px solid #98DCF5;
      background: #E8F7FD;
      text-align: center;
      padding: 8px 16px;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 600;

      .anticon {
        margin-right: 10px;

        path {
          color: #15A9E1;
        }
      }
    }

    .signature-agreement {
      display: flex;
      margin-top: 16px;

      .checkbox {
        margin-right: 10px;
        margin-top: 4px;
      }

      .text {
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.85);
      }
    }

    .footer-wrapper {
      border-top: 1px solid rgba(0, 0, 0, 0.08);
      position: fixed;
      background: #fff;
      z-index: 1;
      margin-right: 16px;
      bottom: 0px;

      .content {
        padding: 16px 16px 10px 16px;
      }

      .footer-error {
        margin-top: 10px;
      }
    }

    .footer-btn {
      margin-top: 16px !important;
    }
  }
}

.preview-data-container {
  min-height: 160px;
  align-content: center;

  .digitally-signed {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.85);
  }

  .auto-signature {
    font-size: 24px;
    line-height: 36px;
    color: rgba(0, 0, 0, 0.85);
    padding: 8px 0px;
  }

  .signature-image {
    max-height: 120px;
    max-width: 170px;
  }

  .signature-name {
    font-size: 16px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 700;
  }

  .signature-role {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.45);
    line-height: 22px;
  }

  .signature-phone {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 22px;
  }

  .signature-report-date {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
    line-height: 22px;
  }

  .signature-qualification {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 22px;
  }

  .signature-free-text {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 22px;
  }
}
@white: #fff;@black: #000;@primary-color: #04a9e3;@primary-color-2: #04a9e3;@primary-color-accent: #1890ff;@border-color: #adafb2;@border-color-dark: #9b9b9b;@chart-table-border-color: #00000026;@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC',
  'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial,
  sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@font-size-base: 14px;@font-size-lg: @font-size-base + 2px;@font-size-sm: 14px;@alem-dark-blue: #0f2549;@layout-header-background: @alem-dark-blue;@layout-sider-background: @alem-dark-blue;@layout-trigger-background: #002140;@layout-trigger-color: #fff;@card-head-background: #f4f3f3;@heading-color: fade(#000, 85%);@heading-color-dark: fade(#fff, 100%);@icon-color: inherit;@icon-color-hover: fade(@black, 75%);@text-color: #666;@text-color-secondary: fade(@black, 45%);@text-color-inverse: @white;@text-color-dark: fade(@white, 85%);@text-color-secondary-dark: fade(@white, 65%);@text-selection-bg: @primary-color;@border-radius-base: 3px;@border-radius-sm: 2px;@shadow-color: rgba(0, 0, 0, 0.05);@shadow-1-down: 4px 4px 40px @shadow-color;@border-color-split: #f4f4f4;@border-color-base: #e5e5e5;@hover-color: #f9f9fc;@header-height: 60px;