@import url(https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap&.css);
@import url(https://fonts.googleapis.com/css2?family=Sacramento&display=swap&.css);
@import url(https://fonts.googleapis.com/css2?family=Kaushan+Script&family=Sacramento&display=swap&.css);
@import url(https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Kaushan+Script&family=Sacramento&display=swap&.css);
@import url(https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap&.css);
@import url(https://fonts.googleapis.com/css2?family=Sacramento&display=swap&.css);
@import url(https://fonts.googleapis.com/css2?family=Kaushan+Script&family=Sacramento&display=swap&.css);
@import url(https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Kaushan+Script&family=Sacramento&display=swap&.css);
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.text-gradient {
  background-image: -webkit-gradient(linear, 37.219838% 34.532506%, 36.425669% 93.178216%, from(#29cdff), to(#0a60ff), color-stop(0.37, #148eff));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.background-hover {
  transition: all 0.3s ease-out;
}
.background-hover:hover {
  background-color: #f9f9fc;
}
.brand {
  z-index: 1;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 14px;
}
.brand .logo {
  display: flex;
  align-items: center;
  justify-content: center;
}
.brand .logo > .name {
  -webkit-animation: _2E57quuEHvm0P04HV-eeCJ 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
          animation: _2E57quuEHvm0P04HV-eeCJ 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
.brand .logo img {
  width: 36px;
}
.header {
  padding: 0;
  box-shadow: 4px 4px 40px 0 rgba(0, 0, 0, 0.05);
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 60px;
  z-index: 9;
  align-items: center;
  background-color: #fff;
}
.header.ant-progress-success-bg,
.header .ant-progress-bg {
  background-color: #04a9e3 !important;
}
.header.collapsed .brand {
  padding-right: 24px;
}
.header.fixed {
  position: fixed;
  top: 0;
  right: 0;
  width: calc(100%);
  z-index: 29;
  transition: width 0.2s;
}
.header.fixed.collapsed {
  width: calc(100%);
}
.header .ant-menu-submenu-title {
  height: 60px;
}
.header .ant-menu-horizontal {
  line-height: 60px;
}
.header .ant-menu-horizontal > .ant-menu-submenu:hover {
  color: #04a9e3;
  background-color: #f9f9fc;
}
.header .ant-menu {
  border-bottom: none;
  height: 60px;
}
.header .ant-menu-horizontal > .ant-menu-submenu {
  top: 0;
  margin-top: 0;
}
.header .ant-menu-horizontal > .ant-menu-item,
.header .ant-menu-horizontal > .ant-menu-submenu {
  border-bottom: none;
}
.header .ant-menu-horizontal > .ant-menu-item-active,
.header .ant-menu-horizontal > .ant-menu-item-open,
.header .ant-menu-horizontal > .ant-menu-item-selected,
.header .ant-menu-horizontal > .ant-menu-item:hover,
.header .ant-menu-horizontal > .ant-menu-submenu-active,
.header .ant-menu-horizontal > .ant-menu-submenu-open,
.header .ant-menu-horizontal > .ant-menu-submenu-selected,
.header .ant-menu-horizontal > .ant-menu-submenu:hover {
  border-bottom: none;
}
.header .leftContainer {
  display: flex;
  align-items: center;
}
.header .rightContainer {
  display: flex;
  align-items: center;
}
.header .button {
  color: #1890ff;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.3s ease-out;
}
.header .button:hover {
  color: #04a9e3;
  background-color: #f9f9fc;
}
.iconButton {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  cursor: pointer;
  transition: all 0.3s ease-out;
}
.iconButton:hover {
  background-color: #f9f9fc;
}
.iconButton:hover .iconFont {
  color: #04a9e3;
}
.iconButton + .iconButton {
  margin-left: 8px;
}
.iconButton .iconFont {
  color: #6d6c6c;
  font-size: 20px;
}
.notification {
  padding: 24px 0;
  width: 320px;
}
.notification .notificationItem {
  transition: all 0.3s;
  padding: 12px 24px;
  cursor: pointer;
}
.notification .notificationItem:hover {
  background-color: #f9f9fc;
}
.notification .clearButton {
  text-align: center;
  height: 48px;
  line-height: 48px;
  cursor: pointer;
  transition: all 0.3s ease-out;
}
.notification .clearButton:hover {
  background-color: #f9f9fc;
}
.notificationPopover .ant-popover-inner-content {
  padding: 0;
}
.notificationPopover .ant-popover-arrow {
  display: none;
}
.notificationPopover .ant-list-item-content {
  flex: 0 1;
  margin-left: 16px;
}
@media (max-width: 767px) {
  .header {
    width: 100% !important;
  }
}

.menu-bottom {
  width: 100%;
}
.menu-bottom .ant-menu-item:last-of-type {
  margin-bottom: 0;
}
.connection-wrapper {
  padding: 0 20px;
}
.connection {
  background: transparent;
  color: #fff;
  border-style: solid;
  border-width: 1px;
  border-color: #fff;
  display: block;
  padding: 6px 6px;
  text-align: center;
  width: 100%;
  border-radius: 3px;
  font-size: 14px;
}
.connection:hover {
  color: #abb2bf;
  border-color: #abb2bf;
}

.bread {
  margin-bottom: 24px;
  font-size: 22px;
}
.bread .ant-breadcrumb {
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .bread {
    margin-bottom: 12px;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.text-gradient {
  background-image: -webkit-gradient(linear, 37.219838% 34.532506%, 36.425669% 93.178216%, from(#29cdff), to(#0a60ff), color-stop(0.37, #148eff));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.background-hover {
  transition: all 0.3s ease-out;
}
.background-hover:hover {
  background-color: #f9f9fc;
}
.sider {
  box-shadow: rgba(4, 169, 227, 0.1) 0 0 28px 0;
  z-index: 10;
}
.sider .ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 60px);
}
.header {
  z-index: 1;
  height: 60px;
  display: flex;
}
.menuContainer {
  overflow-x: hidden;
  flex: 1 1;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.menuContainer::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.menuContainer:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}
.menuContainer .ant-menu-dark .ant-menu-item.ant-menu-item-selected .anticon,
.menuContainer .ant-menu-dark .ant-menu-item.ant-menu-item-active .anticon,
.menuContainer .ant-menu-dark .ant-menu-item.ant-menu-item-selected a span,
.menuContainer .ant-menu-dark .ant-menu-item.ant-menu-item-active a span {
  color: #29cdff;
}
.menuContainer .ant-menu-dark .ant-menu-item a {
  color: #fff !important;
}
.menuContainer .ant-menu-dark .ant-menu-item a > * {
  font-size: 14px;
}
.menuContainer .ant-menu-dark .ant-menu-submenu-title > * {
  color: #fff !important;
  font-size: 14px;
}
.menuContainer .ant-menu-dark > .ant-menu-submenu-selected,
.menuContainer .ant-menu-dark .ant-menu-item-selected {
  background-color: transparent;
}
.menuContainer .ant-menu-dark .ant-menu-submenu-open > * {
  box-shadow: none !important;
  background-color: transparent;
}
@-webkit-keyframes fadeLeftIn {
  0% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fadeLeftIn {
  0% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.text-gradient {
  background-image: -webkit-gradient(linear, 37.219838% 34.532506%, 36.425669% 93.178216%, from(#29cdff), to(#0a60ff), color-stop(0.37, #148eff));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.background-hover {
  transition: all 0.3s ease-out;
}
.background-hover:hover {
  background-color: #f9f9fc;
}
.contentInner {
  background: #fff;
  padding: 24px;
  box-shadow: 4px 4px 20px 0 rgba(0, 0, 0, 0.01);
  min-height: calc(100vh - 230px);
  position: relative;
}
@media (max-width: 767px) {
  .contentInner {
    padding: 12px;
    min-height: calc(100vh - 160px);
  }
}

.error {
  color: black;
  text-align: center;
  position: absolute;
  top: 30%;
  margin-top: -50px;
  left: 50%;
  margin-left: -100px;
  width: 200px;
}
.error .anticon {
  font-size: 48px;
  margin-bottom: 16px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.text-gradient {
  background-image: -webkit-gradient(linear, 37.219838% 34.532506%, 36.425669% 93.178216%, from(#29cdff), to(#0a60ff), color-stop(0.37, #148eff));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.background-hover {
  transition: all 0.3s ease-out;
}
.background-hover:hover {
  background-color: #f9f9fc;
}
.backTop {
  right: 50px;
}
.backTop .ant-back-top-content {
  opacity: 0.3;
  transition: all 0.3s;
  box-shadow: 0 0 15px 1px rgba(69, 65, 78, 0.1);
}
.backTop .ant-back-top-content:hover {
  opacity: 1;
}
.content {
  padding: 24px;
  min-height: 100vh;
}
.white-background {
  background: #FFFFFF;
}
@media (max-width: 767px) {
  .content {
    padding: 12px;
  }
  .backTop {
    right: 20px;
    bottom: 20px;
  }
  .container {
    height: 100vh;
    flex: 1 1;
    width: 100%;
  }
}
.table-dashboard-study-details tr,
.table-dashboard-study-details td {
  border: 0px !important;
}
.table-with-total table,
.table-with-total tbody tr:last-child,
.table-with-total tbody tr:last-child td {
  border: 0px !important;
}
.table-dashboard-radiologist-performance td {
  padding: 10px 20px !important;
  border-color: #adafb2 !important;
}
.table-dashboard-radiologist-performance .ant-table-content td {
  border-top-color: transparent !important;
}
.table-dashboard-radiologist-performance tr {
  border: 0px !important;
}
.table-dashboard-radiologist-performance table,
.table-dashboard table,
.table-dashboard tr {
  border-left: 0px !important;
  border-right: 0px !important;
}
.table-dashboard-radiologist-performance--footer th {
  border-top-color: transparent !important;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.text-gradient {
  background-image: -webkit-gradient(linear, 37.219838% 34.532506%, 36.425669% 93.178216%, from(#29cdff), to(#0a60ff), color-stop(0.37, #148eff));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.background-hover {
  transition: all 0.3s ease-out;
}
.background-hover:hover {
  background-color: #f9f9fc;
}
@font-face {
  font-family: 'Signatura Monoline Script';
  src: url(/static/media/SignaturaMonolineScript.1d03aea3.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}
body {
  height: 100%;
  background-color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
#dashboard #study-details-card-form .ant-radio-group .ant-radio-button-wrapper:not(:first-child)::before,
#routing-preferences #study-details-card-form .ant-radio-group .ant-radio-button-wrapper:not(:first-child)::before,
#routing-preferences-create-route #study-details-card-form .ant-radio-group .ant-radio-button-wrapper:not(:first-child)::before,
#dashboard #radiologist-performance-card-form .ant-radio-group .ant-radio-button-wrapper:not(:first-child)::before,
#routing-preferences #radiologist-performance-card-form .ant-radio-group .ant-radio-button-wrapper:not(:first-child)::before,
#routing-preferences-create-route #radiologist-performance-card-form .ant-radio-group .ant-radio-button-wrapper:not(:first-child)::before,
#dashboard #routes-filter-form .ant-radio-group .ant-radio-button-wrapper:not(:first-child)::before,
#routing-preferences #routes-filter-form .ant-radio-group .ant-radio-button-wrapper:not(:first-child)::before,
#routing-preferences-create-route #routes-filter-form .ant-radio-group .ant-radio-button-wrapper:not(:first-child)::before,
#dashboard #routes-create-form .ant-radio-group .ant-radio-button-wrapper:not(:first-child)::before,
#routing-preferences #routes-create-form .ant-radio-group .ant-radio-button-wrapper:not(:first-child)::before,
#routing-preferences-create-route #routes-create-form .ant-radio-group .ant-radio-button-wrapper:not(:first-child)::before {
  background-color: unset;
}
#dashboard #study-details-card-form .ant-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before,
#routing-preferences #study-details-card-form .ant-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before,
#routing-preferences-create-route #study-details-card-form .ant-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before,
#dashboard #radiologist-performance-card-form .ant-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before,
#routing-preferences #radiologist-performance-card-form .ant-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before,
#routing-preferences-create-route #radiologist-performance-card-form .ant-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before,
#dashboard #routes-filter-form .ant-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before,
#routing-preferences #routes-filter-form .ant-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before,
#routing-preferences-create-route #routes-filter-form .ant-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before,
#dashboard #routes-create-form .ant-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before,
#routing-preferences #routes-create-form .ant-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before,
#routing-preferences-create-route #routes-create-form .ant-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: unset !important;
}
.ant-radio-group .ant-radio-button-wrapper:not(:first-child)::before {
  background-color: unset;
}
.ant-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: unset !important;
}
.qc-table .ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0;
}
.qc-table .ant-table-thead {
  background-color: #00000005;
}
.qc-table .ant-table-expanded-row,
.qc-table .ant-table-expanded-row:hover {
  background: none;
}
.h5 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}
.table-expanded-row .ant-table-row-cell-break-word {
  padding: 8px;
}
.table-expanded-row .ant-table-tbody > tr > td {
  border-bottom: none;
}
#nprogress {
  pointer-events: none;
}
#nprogress .bar {
  background: #04a9e3;
  position: fixed;
  z-index: 2048;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 2px;
}
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #04a9e3, 0 0 5px #04a9e3;
  opacity: 1;
  -webkit-transform: rotate(3deg) translate(0, -4px);
          transform: rotate(3deg) translate(0, -4px);
}
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}
#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  border: solid 2px transparent;
  border-top-color: #04a9e3;
  border-left-color: #04a9e3;
  border-radius: 50%;
}
#nprogress .spinner-icon {
  -webkit-animation: _3pDd2G2eyZchTP6AkIRXWN 400ms linear infinite;
          animation: _3pDd2G2eyZchTP6AkIRXWN 400ms linear infinite;
}
.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}
.nprogress-custom-parent #nprogress .bar,
.nprogress-custom-parent #nprogress .spinner {
  position: absolute;
}
@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.custom-dropdown-checkbox-container .custom-dropdown {
  padding: 5px 10px;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  width: 100%;
}
.custom-dropdown-checkbox-container .custom-dropdown .dropdown-text {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.custom-dropdown-checkbox-container .custom-dropdown .dropdown-text-placeholder {
  font-size: 14px;
}
.custom-dropdown-checkbox-container .dropdown-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.custom-dropdown-checkbox-container .dropdown-label {
  text-align: start;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: rgba(0, 0, 0, 0.45);
}
.custom-checkbox-container {
  padding: 8px;
}
.custom-checkbox-container .search-box {
  margin: 10px 0px;
}
.custom-checkbox-container .field-checkbox-container {
  max-height: 22vh;
  overflow: auto;
}
.custom-checkbox-container .checkbox-inner-container {
  overflow: auto;
}
.custom-checkbox-container .checkbox-inner-container .ant-checkbox-group {
  display: flex;
  flex-direction: column;
}
.custom-checkbox-container .checkbox-inner-container .ant-checkbox-group .ant-checkbox-group-item {
  padding-bottom: 8px;
}
.custom-checkbox-container .dropdown-buttons {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}
.custom-checkbox-container .ant-menu-vertical {
  border: none;
}
.custom-checkbox-container ::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
}
.custom-checkbox-container ::-webkit-scrollbar-thumb {
  width: 4px;
  margin-top: -5px;
  margin-bottom: -5px;
  background: #ccc;
  border: 2px;
  border-radius: 20px;
}

.check-box-element {
  padding-bottom: 12px;
}

.checkbox-group-item .ant-checkbox + span {
  padding-left: 10px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.text-gradient {
  background-image: -webkit-gradient(linear, 37.219838% 34.532506%, 36.425669% 93.178216%, from(#29cdff), to(#0a60ff), color-stop(0.37, #148eff));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.background-hover {
  transition: all 0.3s ease-out;
}
.background-hover:hover {
  background-color: #f9f9fc;
}
.link-button {
  background-color: transparent;
  border: none;
  display: inline;
  margin: 0;
  padding: 0;
}
.link-button.normal {
  cursor: pointer;
  color: #04a9e3;
}
.link-button.red {
  cursor: pointer;
  color: #E5625F;
}
.link-button.disabled {
  color: #adafb2;
}
.link-button:hover,
.link-button:focus {
  text-decoration: none;
}
.link-button:hover .normal,
.link-button:focus .normal {
  color: #1890ff;
}
.link-button:hover.red,
.link-button:focus.red {
  color: #E5382E;
}

.login-title {
  min-height: 76px;
  background: url(/static/media/alem_health_logo.89bc9e3b.png) no-repeat center;
  margin-top: 20px;
}
.login-sub-title {
  margin-top: 20px;
}
.login-sub-title p {
  font-size: 16px;
  text-align: center;
}
.login-box {
  border: 1px solid #e5e5e5;
  padding: 10px;
}
.login-field-label {
  color: #070c0e;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}
.login-form-forgot-password {
  margin-top: 10px;
  font-family: 'Noto Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #15a9e1;
}

.home-login {
  height: 100vh !important;
}
.home-login .home-login-right-col-logo {
  background: url(/static/media/alem_health_logo.f6e52969.svg) no-repeat center;
  width: 150px;
  height: 30px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
}
.home-login .home-login-right-col .home-login-right-col-content {
  padding: 24px !important;
  box-sizing: content-box;
  padding: 24px 24px 24px;
  width: 305px;
  margin: auto;
}
.home-login .home-login-right-col .home-login-right-col-content .qr-scanned-button {
  width: 100%;
  margin-top: 38px;
}
.home-login .home-login-right-col .home-login-right-col-content .qr-scanned-button.qr-scanned-button--no-margin {
  margin: 0;
}
.home-login .home-login-right-col .home-login-right-col-content .home-login-right-col-body .qr-scanned-faild {
  background: #ff00000a;
}
.home-login .home-login-right-col .home-login-right-col-content .home-login-right-col-body .qr-scanned-faild,
.home-login .home-login-right-col .home-login-right-col-content .home-login-right-col-body .qr-scanned-success {
  padding: 30px;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  margin-top: 24px;
  text-align: center;
}
.home-login .home-login-right-col .home-login-right-col-content .home-login-right-col-body .qr-scanned-faild .home-login-check-circle,
.home-login .home-login-right-col .home-login-right-col-content .home-login-right-col-body .qr-scanned-success .home-login-check-circle {
  display: block;
  margin-bottom: 10px;
}
.home-login .home-login-right-col .home-login-right-col-content .home-login-right-col-body .qr-scanned-faild .home-login-check-circle-img,
.home-login .home-login-right-col .home-login-right-col-content .home-login-right-col-body .qr-scanned-success .home-login-check-circle-img {
  height: 60px;
  margin-bottom: 20px;
}
.home-login .home-login-right-col .home-login-right-col-content .home-login-right-col-body .qr-scanned-success .home-login-check-circle {
  color: #04a9e3;
}
.home-login .home-login-right-col .home-login-right-col-content .home-login-right-col-body .qr-scanned-faild .home-login-check-circle {
  color: red;
}
.home-login .home-login-right-col .home-login-right-col-content .home-login-right-col-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.home-login .home-login-right-col .home-login-right-col-content .home-login-right-col-title {
  font-family: 'Noto Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: #606060;
  margin: 40px 0 0;
}
.home-login .home-login-right-col .home-login-right-col-content .home-login-right-col-paragraph {
  font-family: 'Noto Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  color: #606060;
  margin: 14px 0 26px;
}
.home-login .home-login-right-col .home-login-right-col-content .home-login-right-col-login-btn {
  background: #04a9e3;
  color: #fff;
}
.home-login .home-login-right-col .home-login-right-col-content .home-login-right-col-login-divider {
  padding: 0 24px;
}
.home-login .home-login-right-col .home-login-right-col-content .home-login-right-col-login-divider .ant-divider {
  background-color: unset;
  margin: 20px 0;
}
.home-login .home-login-right-col .home-login-right-col-content .home-login-right-col-login-divider .ant-divider .ant-divider-inner-text {
  font-family: 'Noto Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 28px;
  color: #c4c4c4;
  font-family: Roboto, sans-serif;
}
.home-login .home-login-right-col .home-login-right-col-content .home-login-right-col-create-account-btn {
  border: 1px solid #b9b9b9;
}
.home-login .home-login-right-col .home-login-right-col-content .home-login-right-col-links {
  margin-top: 24px;
}
.home-login .home-login-right-col .home-login-right-col-content .home-login-right-col-links a {
  font-family: 'Noto Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 28px;
  color: #04a9e3;
  text-decoration: underline;
}
.home-login.qr-scanned {
  max-width: 420px;
  width: 100%;
  margin: auto !important;
}
.home-login .home-login-main-col {
  background-color: #f2f2f2;
  height: 100%;
}
.home-login .home-login-main-col .home-login-main-col-background-img {
  background: url(/static/media/map-logo.6284196f.svg) no-repeat center;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 'center';
  width: 100%;
  max-width: 800px;
  height: 500px;
  margin: 100px auto 0;
}

.revoke-body {
  width: 87%;
}
.revoke-title > .text {
  margin-left: 14px;
}

.group-facilities > p {
  font-weight: bold;
  margin: -12px 0 17px 0;
}
.ant-radio-group.admin-access {
  width: 100%;
}
.ant-radio-group.admin-access .ant-radio-button-wrapper {
  width: 50%;
  text-align: center;
}
.ant-modal-footer {
  background: #fff;
}
.user-form__actions .ant-row.ant-form-item {
  margin: 0;
  text-align: left;
}
.user-form__actions .ant-row.ant-form-item .ant-form-item-label {
  line-height: 26px;
}
.user-form__actions .ant-row.ant-form-item .ant-form-item-control {
  line-height: 30px;
}
.lateral-modal .facility-container {
  height: 300px;
  overflow: auto;
}
.lateral-modal .signature-card .empty-sign-text {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
}
.lateral-modal .signature-card .ant-empty-image {
  height: 100px;
}
.lateral-modal .signature-card .ant-empty-image svg {
  width: 180px;
  height: 100px;
}
.lateral-modal .signature-card .ant-empty-normal {
  margin: 0px;
}
.lateral-modal .signature-card .signature-delete-btn {
  color: #FF4D4F;
}
.lateral-modal .main-preview-card .ant-card-body {
  display: flex;
  justify-content: center;
}
.lateral-modal ::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
}
.lateral-modal ::-webkit-scrollbar-thumb {
  width: 4px;
  margin-top: -5px;
  margin-bottom: -5px;
  background: #ccc;
  border: 2px;
  border-radius: 20px;
}

.ant-modal-body {
  position: relative;
}
.signature-modal .ant-modal-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 22;
  background: white;
}
.signature-modal .ant-modal-body {
  padding: 0px;
}
.signature-modal .ant-modal-close {
  position: fixed;
  top: 0px;
  right: 24px;
  z-index: 3333;
}
.signature-form .signature-header {
  position: -webkit-sticky;
  position: sticky;
  top: 55px;
  z-index: 22;
  background: white;
  padding: 24px 24px 10px 24px;
}
.signature-form .signature-header .header-wrapper .preview .ant-card-body {
  display: flex;
  justify-content: center;
}
.signature-form .signature-header .header-wrapper .preview .empty-container {
  width: 260px;
  text-align: center;
  min-height: 180px;
  align-content: center;
}
.signature-form .signature-header .header-wrapper .header-separator {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  height: 1px;
  margin: 16px 0px 0px 0px;
}
.signature-form .signature-body {
  padding: 0px 24px;
}
.signature-form .ant-form-item {
  margin-bottom: 0px;
}
.signature-form .ant-form-item .ant-form-item-control {
  line-height: unset;
}
.signature-form .label {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.signature-form .label .asterisk-text {
  color: #F5222D;
}
.signature-form .signature-mode {
  margin-bottom: 16px;
}
.signature-form .signature-mode-radio {
  display: flex;
}
.signature-form .signature-mode .ant-radio-button-wrapper {
  width: 100%;
  text-align: center;
}
.signature-form .digitally-signed-switch {
  margin-bottom: 16px;
}
.signature-form .font-field {
  margin-bottom: 16px;
}
.signature-form .upload-btn {
  margin-bottom: 16px;
}
.signature-form .upload-btn .upload-file-item {
  display: flex;
  align-items: center;
}
.signature-form .upload-btn .filename {
  font-size: 14px;
  line-height: 22px;
  color: #15A9E1;
  width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 10px;
}
.signature-form .upload-btn-input {
  width: 100%;
}
.signature-form .upload-btn-input .ant-upload {
  width: 100%;
}
.signature-form .upload-btn-text {
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
}
.signature-form .optional-fields .optional-field-box {
  border: 0.5px dashed;
  border-color: rgba(0, 0, 0, 0.15);
  padding: 12px;
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
}
.signature-form .optional-fields .optional-field-box .reporting-date-text {
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
  margin-top: 5px;
}
.signature-form .optional-fields .optional-field-box .ant-switch {
  margin-right: 10px;
}
.signature-form .optional-fields .optional-field-box .reporting-date-radio {
  width: 100%;
  display: flex;
}
.signature-form .optional-fields .optional-field-box .reporting-date-radio .ant-radio-button-wrapper {
  width: 100%;
  text-align: center;
}
.signature-form .error-text {
  font-size: 14px;
  line-height: 22px;
  color: #FF4D4F;
}
.signature-form .signature-footer {
  margin-top: 10px;
  position: relative;
  height: 200px;
}
.signature-form .signature-footer .encryption-section {
  border: 1px solid #98DCF5;
  background: #E8F7FD;
  text-align: center;
  padding: 8px 16px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
}
.signature-form .signature-footer .encryption-section .anticon {
  margin-right: 10px;
}
.signature-form .signature-footer .encryption-section .anticon path {
  color: #15A9E1;
}
.signature-form .signature-footer .signature-agreement {
  display: flex;
  margin-top: 16px;
}
.signature-form .signature-footer .signature-agreement .checkbox {
  margin-right: 10px;
  margin-top: 4px;
}
.signature-form .signature-footer .signature-agreement .text {
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
}
.signature-form .signature-footer .footer-wrapper {
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  position: fixed;
  background: #fff;
  z-index: 1;
  margin-right: 16px;
  bottom: 0px;
}
.signature-form .signature-footer .footer-wrapper .content {
  padding: 16px 16px 10px 16px;
}
.signature-form .signature-footer .footer-wrapper .footer-error {
  margin-top: 10px;
}
.signature-form .signature-footer .footer-btn {
  margin-top: 16px !important;
}
.preview-data-container {
  min-height: 160px;
  align-content: center;
}
.preview-data-container .digitally-signed {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.85);
}
.preview-data-container .auto-signature {
  font-size: 24px;
  line-height: 36px;
  color: rgba(0, 0, 0, 0.85);
  padding: 8px 0px;
}
.preview-data-container .signature-image {
  max-height: 120px;
  max-width: 170px;
}
.preview-data-container .signature-name {
  font-size: 16px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 700;
}
.preview-data-container .signature-role {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.45);
  line-height: 22px;
}
.preview-data-container .signature-phone {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 22px;
}
.preview-data-container .signature-report-date {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  line-height: 22px;
}
.preview-data-container .signature-qualification {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 22px;
}
.preview-data-container .signature-free-text {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 22px;
}

.user-management-header {
  margin-bottom: 20px;
}
.user-management-header .user-management-title {
  line-height: 24px;
  font-weight: 700;
  font-size: 20px;
  color: #404047;
  margin-bottom: 5px;
}
.lateral-modal .ant-modal {
  position: absolute;
  min-height: 100%;
  width: auto;
  margin: 0 auto;
  top: 0;
  right: 0;
  bottom: 0;
}
.lateral-modal .ant-modal-content {
  min-height: 100vh;
  justify-content: space-between;
}
.lateral-modal .ant-modal-footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px 10px 10px;
  text-align: right;
  border-radius: 0 0 4px 4px;
}
.invite-table,
.user-table {
  width: 100%;
}
.invite-table .ant-table-pagination.ant-pagination,
.user-table .ant-table-pagination.ant-pagination {
  float: unset;
}
.invite-table .ant-table-small,
.user-table .ant-table-small {
  border: none;
}
.invite-table .table-action-buttons,
.user-table .table-action-buttons {
  display: flex;
}

.upload-button {
  border: 1px dashed #9b9b9b;
  margin-right: 15px;
}
.upload-wrapper {
  display: flex;
  align-items: center;
}

.labeled-label {
  color: rgba(0, 0, 0, 0.85);
  position: relative;
  top: -0.5px;
  margin: 0 8px -5px 2px;
}

.detail-image-show .logo-badge {
  border: 1px solid rgba(0, 0, 0, 0.4);
  height: 100px;
  border-radius: 4px;
}
.detail-image-show .ant-avatar {
  background: #fff;
}
.detail-image-show .ant-avatar-lg {
  width: 14vw;
  height: 80px;
  line-height: 80px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.text-gradient {
  background-image: -webkit-gradient(linear, 37.219838% 34.532506%, 36.425669% 93.178216%, from(#29cdff), to(#0a60ff), color-stop(0.37, #148eff));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.background-hover {
  transition: all 0.3s ease-out;
}
.background-hover:hover {
  background-color: #f9f9fc;
}
.facilities-header {
  margin-bottom: 0px;
  min-height: 40px;
}
.facility-chart {
  margin-bottom: 30px;
}
.card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
  background: transparent;
}
.card-container > .ant-tabs-card > .ant-tabs-bar {
  border-bottom: 0.5px solid #adafb2;
}
.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border-color: #adafb2;
  background: transparent;
}
.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  border-color: #adafb2;
  background: #d6dade;
  color: black;
  font-weight: bold;
}
.table-tabs .ant-tabs-bar {
  margin-bottom: 0px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.text-gradient {
  background-image: -webkit-gradient(linear, 37.219838% 34.532506%, 36.425669% 93.178216%, from(#29cdff), to(#0a60ff), color-stop(0.37, #148eff));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.background-hover {
  transition: all 0.3s ease-out;
}
.background-hover:hover {
  background-color: #f9f9fc;
}
.common-table .ant-table .ant-table-thead > tr > th {
  padding: 10px 20px;
  border-color: #adafb2;
  text-align: left;
}
.common-table .ant-table .ant-table-thead > tr > th .ant-table-column-sorter {
  vertical-align: baseline;
  padding-top: 2px;
}
.common-table .ant-table .ant-table-thead > tr > th .anticon-filter,
.common-table .ant-table .ant-table-thead > tr > th .anticon-search {
  right: auto;
}
.common-table .ant-table .ant-table-content .ant-table-body tr {
  border: 0.5px solid #adafb2;
  background-color: #fff;
}
.common-table .ant-table table {
  border-right: 0.5px solid #adafb2;
  border-left: 0.5px solid #adafb2;
  border-collapse: collapse;
}
.common-table .ant-table .ant-table-content .ant-table-body tr > td {
  border-top: 0.5px solid #adafb2;
  border-bottom: 0.5px solid #adafb2;
  padding: 10px 20px;
  position: relative;
}
.common-table .ant-table .ant-table-tbody > tr:hover > td {
  background-color: #fff !important;
}
.common-table .ant-table .ant-table-content .ant-table-body tr:hover {
  cursor: pointer;
}
.common-table .ant-pagination {
  width: 100%;
}
.common-table .ant-table-placeholder {
  border: 1px solid #adafb2 !important;
}

.statusCircle {
  border-color: white;
  border-radius: 50%;
  border-width: 5px;
  height: 10px;
  width: 10px;
  line-height: 10px;
  display: inline-block;
}
.statusCircle.enabled {
  background-color: greenyellow;
}
.statusCircle.disabled {
  background-color: lightgray;
}
.circleSpace {
  margin-left: 0px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.text-gradient {
  background-image: -webkit-gradient(linear, 37.219838% 34.532506%, 36.425669% 93.178216%, from(#29cdff), to(#0a60ff), color-stop(0.37, #148eff));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.background-hover {
  transition: all 0.3s ease-out;
}
.background-hover:hover {
  background-color: #f9f9fc;
}
.reset-password .ant-btn {
  border-color: #04a9e3;
  color: #04a9e3;
  margin: -20px 0 10px 0;
}
.reset-password p {
  font-size: 0.9em;
}
.language_field {
  padding: 0 !important;
}

.ant-avatar-lg {
  width: 320px;
  height: 240px;
  line-height: 240px;
}
.ant-avatar-lg-string {
  position: absolute;
  left: 50%;
  -webkit-transform-origin: 0 center;
          transform-origin: 0 center;
}
.ant-avatar > img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.group-logo-label {
  margin-bottom: 8px;
  display: inline-block;
  color: rgba(0, 0, 0, 0.85);
}
.uploader {
  margin-bottom: 15px;
}
.settings-image-show .group-logo-badge {
  border: 1px solid rgba(0, 0, 0, 0.4);
  height: 100px;
  border-radius: 4px;
}
.settings-image-show .ant-avatar-lg {
  height: 100%;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.text-gradient {
  background-image: -webkit-gradient(linear, 37.219838% 34.532506%, 36.425669% 93.178216%, from(#29cdff), to(#0a60ff), color-stop(0.37, #148eff));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.background-hover {
  transition: all 0.3s ease-out;
}
.background-hover:hover {
  background-color: #f9f9fc;
}
.ant-menu-light {
  background: transparent !important;
  height: 100% !important;
}
.ant-menu-light .ant-menu-item-selected {
  background: rgba(213, 247, 255, 0.6) !important;
  color: #666 !important;
}

.group-letterhead {
  display: flex;
  align-items: center;
  justify-content: center;
}
.group-letterhead img {
  width: 90%;
}
.text-letterhead {
  margin-top: 5px;
}

.revoke-body {
  width: 87%;
}
.revoke-title > .text {
  margin-left: 14px;
}

.group-facilities > p {
  font-weight: bold;
  margin: -12px 0 17px 0;
}
.ant-radio-group.admin-access {
  width: 100%;
}
.ant-radio-group.admin-access .ant-radio-button-wrapper {
  width: 50%;
  text-align: center;
}

.group-facilities > p {
  font-weight: bold;
  margin: -12px 0 17px 0;
}
.ant-radio-group.admin-access {
  width: 100%;
}
.ant-radio-group.admin-access .ant-radio-button-wrapper {
  width: 50%;
  text-align: center;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.text-gradient {
  background-image: -webkit-gradient(linear, 37.219838% 34.532506%, 36.425669% 93.178216%, from(#29cdff), to(#0a60ff), color-stop(0.37, #148eff));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.background-hover {
  transition: all 0.3s ease-out;
}
.background-hover:hover {
  background-color: #f9f9fc;
}
.radiologist-header {
  margin-bottom: 10px;
}
.card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
  background: transparent;
}
.card-container > .ant-tabs-card > .ant-tabs-bar {
  border-bottom: 0.5px solid #adafb2;
}
.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border-color: #adafb2;
  background: transparent;
}
.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  border-color: #adafb2;
  background: #d6dade;
  color: black;
  font-weight: bold;
}
.table-tabs .ant-tabs-bar {
  margin-bottom: 0px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.text-gradient {
  background-image: -webkit-gradient(linear, 37.219838% 34.532506%, 36.425669% 93.178216%, from(#29cdff), to(#0a60ff), color-stop(0.37, #148eff));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.background-hover {
  transition: all 0.3s ease-out;
}
.background-hover:hover {
  background-color: #f9f9fc;
}
.physicians-header {
  margin-bottom: 0px;
  min-height: 40px;
}
.ant-table-thead > tr > th {
  border-top: 1px solid #f4f4f4;
}
.card-container {
  padding: 30px;
  background: #fff;
}
.card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
  background: transparent;
}
.card-container > .ant-tabs-card > .ant-tabs-bar {
  border-bottom: 0.5px solid #adafb2;
}
.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border-color: #adafb2;
  background: transparent;
  width: 185px;
  text-align: center;
  margin: 0;
}
.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  border-color: #adafb2;
  background: #d6dade;
  color: black;
  font-weight: bold;
}
.table-tabs .ant-tabs-bar {
  margin-bottom: 0px;
}
.table-tabs .link-button {
  color: #1890ff;
}
.ant-pagination-item-active {
  border-color: #15a9e1;
}
.ant-pagination-item-active a {
  color: #15a9e1;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.text-gradient {
  background-image: -webkit-gradient(linear, 37.219838% 34.532506%, 36.425669% 93.178216%, from(#29cdff), to(#0a60ff), color-stop(0.37, #148eff));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.background-hover {
  transition: all 0.3s ease-out;
}
.background-hover:hover {
  background-color: #f9f9fc;
}
.facilities-header {
  margin-bottom: 0px;
  min-height: 40px;
}
.facility-chart {
  margin-bottom: 30px;
}
.card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
  background: transparent;
}
.card-container > .ant-tabs-card > .ant-tabs-bar {
  border-bottom: 0.5px solid #adafb2;
}
.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border-color: #adafb2;
  background: transparent;
}
.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  border-color: #adafb2;
  background: #d6dade;
  color: black;
  font-weight: bold;
}
.table-tabs .ant-tabs-bar {
  margin-bottom: 0px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.text-gradient {
  background-image: -webkit-gradient(linear, 37.219838% 34.532506%, 36.425669% 93.178216%, from(#29cdff), to(#0a60ff), color-stop(0.37, #148eff));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.background-hover {
  transition: all 0.3s ease-out;
}
.background-hover:hover {
  background-color: #f9f9fc;
}
.facilities-header {
  margin-bottom: 0px;
  min-height: 40px;
}
.facility-chart {
  margin-bottom: 30px;
}
.card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
  background: transparent;
}
.card-container > .ant-tabs-card > .ant-tabs-bar {
  border-bottom: 0.5px solid #adafb2;
}
.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border-color: #adafb2;
  background: transparent;
}
.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  border-color: #adafb2;
  background: #d6dade;
  color: black;
  font-weight: bold;
}
.table-tabs .ant-tabs-bar {
  margin-bottom: 0px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.text-gradient {
  background-image: -webkit-gradient(linear, 37.219838% 34.532506%, 36.425669% 93.178216%, from(#29cdff), to(#0a60ff), color-stop(0.37, #148eff));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.background-hover {
  transition: all 0.3s ease-out;
}
.background-hover:hover {
  background-color: #f9f9fc;
}
.facilities-header {
  margin-bottom: 0px;
  min-height: 40px;
}
.facility-chart {
  margin-bottom: 30px;
}
.card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
  background: transparent;
}
.card-container > .ant-tabs-card > .ant-tabs-bar {
  border-bottom: 0.5px solid #adafb2;
}
.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border-color: #adafb2;
  background: transparent;
}
.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  border-color: #adafb2;
  background: #d6dade;
  color: black;
  font-weight: bold;
}
.table-tabs .ant-tabs-bar {
  margin-bottom: 0px;
}

@-webkit-keyframes alem-health-pulse {
  100% {
    width: 42px;
    height: 42px;
    box-shadow: 0 0 10px 3px #5FA8DD;
  }
}
@keyframes alem-health-pulse {
  100% {
    width: 42px;
    height: 42px;
    box-shadow: 0 0 10px 3px #5FA8DD;
  }
}
@-webkit-keyframes max-healthcare-pulse {
  100% {
    width: 42px;
    height: 42px;
    box-shadow: 0 0 10px 3px #62B6A9;
  }
}
@keyframes max-healthcare-pulse {
  100% {
    width: 42px;
    height: 42px;
    box-shadow: 0 0 10px 3px #62B6A9;
  }
}
@-webkit-keyframes apollo-hospitals-pulse {
  100% {
    width: 42px;
    height: 42px;
    box-shadow: 0 0 10px 3px #367B9B;
  }
}
@keyframes apollo-hospitals-pulse {
  100% {
    width: 42px;
    height: 42px;
    box-shadow: 0 0 10px 3px #367B9B;
  }
}
.circle,
.alem-health-circle,
.max-healthcare-circle,
.apollo-hospitals-circle {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: inline-block;
}
.alem-health-circle {
  background-color: #5FA8DD;
}
.max-healthcare-circle {
  background-color: #62B6A9;
}
.apollo-hospitals-circle {
  background-color: #367B9B;
}
.provider-name {
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 0;
}
.provider-description {
  color: #AFAEAE;
  padding: 10px 0 10px 0;
}
.request-service > p {
  padding: 0 0 20px 20px;
}
.modality-row {
  padding-bottom: 15px;
}
.modality-group {
  width: 100%;
}
.request-quotation {
  padding: 40px 0 30px 0;
}
.success-message {
  text-align: center;
}
.success-message h3 {
  margin-top: 64px;
  font-weight: bold;
  font-size: 16px;
}
.success-message h4 {
  font-size: 16px;
  margin-bottom: 32px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.text-gradient {
  background-image: -webkit-gradient(linear, 37.219838% 34.532506%, 36.425669% 93.178216%, from(#29cdff), to(#0a60ff), color-stop(0.37, #148eff));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.background-hover {
  transition: all 0.3s ease-out;
}
.background-hover:hover {
  background-color: #f9f9fc;
}
.onboarding-item--done {
  border: 0px;
  background-color: #e1f4d8;
  margin-bottom: 10px;
  padding: 15px 25px;
  color: #000;
  border-radius: 3px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}
.onboarding-item--active {
  border: 0px;
  background-color: #297bb8;
  margin-bottom: 10px;
  padding: 15px 25px;
  color: #fff;
  border-radius: 3px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}
.onboarding-item--waiting {
  border: 0px;
  background-color: #f3fbfd;
  margin-bottom: 10px;
  padding: 15px 25px;
  color: #000;
  border-radius: 3px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}
.onboarding-action-subtitle {
  color: #979797;
  margin: 0px 0px 20px !important;
  display: inline-block !important;
}
.onboarding-action-title {
  margin-bottom: 0px !important;
}
.onboarding-action-add-another {
  color: #1890ff;
  border-color: #1890ff;
  margin-top: 20px;
}
.onboarding-action-buttons {
  margin-top: 20px;
  text-align: right;
}
.onboarding-action-buttons .ant-btn-line {
  border-color: #04a9e3;
  color: #04a9e3;
}


/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.text-gradient {
  background-image: -webkit-gradient(linear, 37.219838% 34.532506%, 36.425669% 93.178216%, from(#29cdff), to(#0a60ff), color-stop(0.37, #148eff));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.background-hover {
  transition: all 0.3s ease-out;
}
.background-hover:hover {
  background-color: #f9f9fc;
}
.card-container > .ant-tabs-card > .ant-tabs-bar {
  border-bottom: 0.5px solid #adafb2;
}
.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border-color: #adafb2;
  background: transparent;
}
.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  border-color: #adafb2;
  background: #d6dade;
  color: black;
  font-weight: bold;
}
.text-link {
  color: #15a9e1;
}

.group-facilities > p {
  font-weight: bold;
  margin: -12px 0 17px 0;
}
.ant-radio-group.admin-access {
  width: 100%;
}
.ant-radio-group.admin-access .ant-radio-button-wrapper {
  width: 50%;
  text-align: center;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.text-gradient {
  background-image: -webkit-gradient(linear, 37.219838% 34.532506%, 36.425669% 93.178216%, from(#29cdff), to(#0a60ff), color-stop(0.37, #148eff));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.background-hover {
  transition: all 0.3s ease-out;
}
.background-hover:hover {
  background-color: #f9f9fc;
}
.card-container > .ant-tabs-card > .ant-tabs-bar {
  border-bottom: 0.5px solid #adafb2;
}
.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border-color: #adafb2;
  background: transparent;
}
.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  border-color: #adafb2;
  background: #d6dade;
  color: black;
  font-weight: bold;
}
.text-link {
  color: #15a9e1;
}

.group-facilities > p {
  font-weight: bold;
  margin: -12px 0 17px 0;
}
.ant-radio-group.admin-access {
  width: 100%;
}
.ant-radio-group.admin-access .ant-radio-button-wrapper {
  width: 50%;
  text-align: center;
}


.checkbox-group-item {
  margin-top: 0px !important;
}

.route-filter-form .ant-form-item {
  margin-bottom: 0px;
}
.route-filter-form .ant-form-item-label {
  line-height: 20px;
  margin-bottom: 5px;
}
.route-filter-form .ant-form-item-label label::after {
  content: "";
}
.route-filter-form .checkbox-group-item .ant-checkbox + span {
  padding-left: 10px;
}

.routes-root .empty-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.routes-root .empty-container .empty-box {
  text-align: center;
  max-width: 460px;
}
.routes-root .empty-container .empty-box .description {
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
  margin-top: 15px;
}
.routes-root .empty-container .empty-box .btn {
  margin-top: 10px;
}
.routes-root .routes-card {
  height: 100%;
}
.routes-root .routes-card .ant-card-body {
  padding: 0px;
}
.routes-root .routes-card .title-container {
  padding: 16px 24px;
}
.routes-root .routes-card .title {
  font-weight: 600;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
}
.routes-root .routes-card .route-description {
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  margin-top: 5px;
}
.routes-root .routes-card .divider {
  margin: 0px;
}
.routes-root .card-detail-container {
  padding: 16px 24px;
}
.routes-root .card-detail-container .item {
  margin-top: 10px;
}
.routes-root .card-detail-container .item .label {
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45) !important;
  font-size: 12px;
}
.routes-root .card-detail-container .item .value {
  color: #000000;
  line-height: 22px;
  font-size: 14px;
}
.routes-root .card-detail-container .item ul {
  padding: 0px 0px 0px 25px;
}
.routes-root .card-detail-container .assign-list {
  height: 210px;
  overflow-y: auto;
  margin-top: 10px;
}
.routes-root .card-detail-container .assign-list .assign-detail {
  display: flex;
}
.routes-root .card-detail-container .assign-list .assign-detail .icon {
  margin-right: 10px;
}
.routes-root .card-detail-container .assign-list .assign-detail .assign-icon {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}
.routes-root .card-detail-container .assign-list .user-name {
  line-height: 22px;
  color: #000000;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.routes-root .card-detail-container .assign-list .user-type {
  line-height: 22px;
  font-weight: 600;
  color: #000000;
  font-size: 12px;
}
.routes-root .card-detail-container .assign-list .assign-date {
  color: rgba(0, 0, 0, 0.45);
}
.routes-root .card-detail-container ::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
}
.routes-root .card-detail-container ::-webkit-scrollbar-thumb {
  width: 4px;
  margin-top: -5px;
  margin-bottom: -5px;
  background: #ccc;
  border: 2px;
  border-radius: 20px;
}
.delete-route-btn {
  border: none;
  padding: 0px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.text-gradient {
  background-image: -webkit-gradient(linear, 37.219838% 34.532506%, 36.425669% 93.178216%, from(#29cdff), to(#0a60ff), color-stop(0.37, #148eff));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.background-hover {
  transition: all 0.3s ease-out;
}
.background-hover:hover {
  background-color: #f9f9fc;
}
.no-content-wrapper {
  padding: 20px;
  margin-bottom: 20px;
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 650px;
  text-align: center;
}
.no-content-wrapper .no-content-item {
  max-width: 340px;
}
.no-content-wrapper .description {
  margin-top: 3em;
}
.no-content-wrapper .button {
  margin-top: 1.5em;
}
.no-content-wrapper .search-container {
  display: flex;
  flex-direction: row;
}
.schedule-wrapper {
  display: flex;
  margin-top: 1rem;
}
.common-label {
  font-weight: 600;
  color: #000000;
  line-height: 22px;
}
.ant-dropdown-menu {
  min-width: 150px;
}
.day-filter-menu {
  min-width: 150px;
}
.day-filter-menu .ant-dropdown-submenu-item {
  min-width: 120px;
}
.radiologist-card {
  display: flex;
  position: relative;
  margin-bottom: 15px;
}
.radiologist-card .rad-avatar {
  position: relative;
  margin-right: 15px;
}
.radiologist-card .rad-avatar .ant-avatar {
  background: #3BBCED;
}
.radiologist-card .rad-avatar .badge {
  position: absolute;
  left: 23px;
  top: -3px;
}
.radiologist-card .rad-avatar .badge .ant-badge-status-dot {
  height: 8px;
  width: 8px;
  border: 1px solid white;
}
.radiologist-card .rad-details {
  margin-right: 40px;
  width: 150px;
}
.radiologist-card .rad-details .rad-name {
  font-weight: 600;
  color: #000000;
  line-height: 22px;
  width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.radiologist-card .rad-details .rad-specialty {
  color: rgba(0, 0, 0, 0.45);
  margin-top: 7px;
}
.radiologist-card .hrs-text {
  position: absolute;
  right: 3px;
  color: rgba(0, 0, 0, 0.45);
}
.time-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 12;
}
.time-wrapper .time-item {
  width: 100%;
  text-align: center;
  position: relative;
  color: rgba(0, 0, 0, 0.85);
  height: 100%;
}
.time-wrapper .time-item .time-background-even {
  height: 100%;
  position: absolute;
  top: 0px;
  bottom: 0%;
  z-index: 22;
  background-color: rgba(0, 0, 0, 0.15);
  opacity: 0.3;
  width: 100%;
  left: 0px;
  right: 0px;
}
.time-wrapper .time-item .time-background-odd {
  height: 100%;
  position: absolute;
  top: 0px;
  bottom: 0%;
  z-index: 22;
  opacity: 0.3;
  width: 100%;
  left: 0px;
  right: 0px;
}
.schedule-card {
  margin-bottom: 20px;
  position: absolute;
  padding: 10px;
  z-index: 15;
}
.schedule-card-content {
  padding: 15px;
  display: flex;
}
.schedule-card-left .radiologist-avatar {
  background: #04a9e3;
}
.schedule-card-left .radiographer-avatar {
  background: #722ED1;
}
.schedule-card-left .it_administrator-avatar {
  background: #2F54EB;
}
.schedule-card-right {
  margin-left: 15px;
}
.schedule-card-name {
  font-weight: 600;
  color: #000000;
  line-height: 22px;
}
.schedule-card-specialty {
  margin-top: 5px;
  color: rgba(0, 0, 0, 0.45);
}
.schedule-card-time {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  color: rgba(0, 0, 0, 0.45);
}
.common-schedule-header {
  width: 100%;
}
.common-schedule-header-radiologist {
  border-bottom: 1px solid #98DCF5;
  margin-top: 20px;
  position: relative;
}
.common-schedule-header-radiologist .ant-tag {
  position: absolute;
  background: #04a9e3;
  color: #fff;
  font-size: 16px;
  bottom: -13px;
  padding: 2px 10px;
  left: 20px;
}
.common-schedule-header-radiographer {
  border-bottom: 1px solid #722ED1;
  margin-top: 20px;
  position: relative;
}
.common-schedule-header-radiographer .ant-tag {
  position: absolute;
  background: #722ED1;
  color: #fff;
  font-size: 16px;
  bottom: -13px;
  padding: 2px 10px;
  left: 20px;
}
.common-schedule-header-it_administrator {
  border-bottom: 1px solid #2F54EB;
  margin-top: 20px;
  position: relative;
}
.common-schedule-header-it_administrator .ant-tag {
  position: absolute;
  background: #2F54EB;
  color: #fff;
  font-size: 16px;
  bottom: -13px;
  padding: 2px 10px;
  left: 20px;
}
.radiologist-background-border {
  border-bottom: 1px solid #04a9e3;
  background-color: #F1FAFE;
  border-radius: 6px;
}
.radiographer-background-border {
  border-bottom: 1px solid #722ED1;
  border-radius: 6px;
  background-color: #F9F0FF;
}
.it_administrator-background-border {
  border-bottom: 1px solid #2F54EB;
  border-radius: 6px;
  background-color: #F0F5FF;
}
.schedule-container {
  border: 1px solid red   ;
}

.create-rout-root .routes-header-wrapper {
  min-height: 240px;
}
.create-rout-root .routes-header-wrapper .bread {
  margin-bottom: 5px;
  font-size: 14px;
}
.create-rout-root .routes-header-wrapper .routes-header {
  background-color: #FFFFFF;
  position: absolute;
  left: -24px;
  right: -24px;
  top: -24px;
  padding: 24px;
  border-bottom: 1px solid;
  border-color: rgba(0, 0, 0, 0.15);
}
.create-rout-root .routes-header-wrapper .routes-header .steps-container {
  color: rgba(0, 0, 0, 0.15);
}
.create-rout-root .routes-header-wrapper .routes-header .steps-container .steps-card {
  margin: 24px 0px 5px 0px;
}
.create-rout-root .routes-content {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.create-rout-root .routes-content .routes-card {
  background: #fff;
  height: 100%;
  width: 600px;
}
.create-rout-root .routes-content .facility-card {
  width: 600px;
}
.create-rout-root .routes-content .facility-card .checkbox-container {
  margin-top: 10px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.create-rout-root .routes-content .facility-card .facility-selection-container {
  height: 240px;
  overflow: auto;
  margin-top: 15px;
}
.create-rout-root .routes-content .facility-card .facility-selection-container .facility-element {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
.create-rout-root .routes-content .facility-card .facility-selection-container .facility-element:hover {
  background: #F5FCFF;
}
.create-rout-root .routes-content .facility-card .facility-selection-container .facility-element:hover .facility-country {
  font-weight: 600;
}
.create-rout-root .routes-content .facility-card .facility-selection-container .facility-element .facility-name {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 4px;
}
.create-rout-root .routes-content .facility-card .facility-selection-container .facility-element .facility-country {
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
}
.create-rout-root .routes-content .facility-card .facility-selection-container .selected-facility {
  background: #F5FCFF;
}
.create-rout-root .routes-content .facility-card .facility-selection-container .selected-facility .facility-country {
  font-weight: 600;
}
.create-rout-root .routes-content .facility-card ::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}
.create-rout-root .routes-content .facility-card ::-webkit-scrollbar-thumb {
  width: 6px;
  margin-top: -5px;
  margin-bottom: -5px;
  background: #ccc;
  border: 2px;
  border-radius: 20px;
}
.create-rout-root .routes-content .day-container {
  margin-top: 15px;
}
.create-rout-root .routes-content .day-container .title {
  font-size: 14px;
  font-weight: 600;
}
.create-rout-root .routes-content .day-container .day-week-container {
  margin-top: 10px;
  display: flex;
}
.create-rout-root .routes-content .day-container .day-week-container .day-week-element {
  padding: 2px 8px;
  background: #faf9f9;
  margin-right: 10px;
  font-size: 14px;
  border-radius: 2px;
  cursor: pointer;
}
.create-rout-root .routes-content .day-container .day-week-container .active-day {
  background: #15A9E1;
  color: #FFFFFF;
}
.create-rout-root .routes-content .day-container .days-container {
  margin-top: 10px;
  display: flex;
}
.create-rout-root .routes-content .day-container .days-container .day-week-element {
  padding: 2px 8px;
  background: #faf9f9;
  margin-right: 10px;
  font-size: 14px;
  border-radius: 2px;
  cursor: pointer;
}
.create-rout-root .routes-content .day-container .days-container .active-day {
  background: #15A9E1;
  color: #FFFFFF;
}
.create-rout-root .routes-content .day-container .time-picker {
  margin-top: 5px;
}
.create-rout-root .routes-content .day-container .switch-container {
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.create-rout-root .routes-content .day-container .switch-container .switch {
  margin-right: 7px;
}
.create-rout-root .routes-content .day-container .time-picker-container {
  display: flex;
}
.create-rout-root .routes-content .day-container .time-picker-container .time-picker {
  width: 100%;
}
.create-rout-root .routes-content .day-container .time-zone-utc {
  color: #00000073;
}
.create-rout-root .routes-content .timezone-box {
  background: #E6F7FF;
  border-radius: 6px;
  padding: 8px 10px;
  margin: 8px 0px;
}
.create-rout-root .routes-content .timezone-box .utc-location-text {
  color: #00000073;
  font-size: 12px;
  line-height: 19px;
}
.create-rout-root .routes-content .ant-card-bordered {
  border: 1px solid #e6e6e6;
  border-radius: 8px;
}
.create-rout-root .routes-content .error-message {
  text-align: left;
  margin-top: 10px;
  font-size: 14px;
}
.create-rout-root .routes-content .assignee-select {
  width: 100%;
  margin-top: 8px;
}
.create-rout-root .routes-content .assignee-dropdown .ant-select-dropdown-menu-item {
  display: flex;
  align-items: center;
}
.create-rout-root .routes-content .route-detail-card .label {
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 8px;
}
.create-rout-root .routes-content .route-detail-card .required-star {
  color: #FF4D4F;
}
.create-rout-root .routes-content .route-detail-card .optional-text {
  color: rgba(0, 0, 0, 0.45);
}
.create-rout-root .routes-content .selected-assignee-container {
  margin-top: 20px;
}
.create-rout-root .routes-content .selected-assignee-container .selected-assignee-card {
  border: 1px dashed;
  border-color: rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  padding: 12px;
  margin-bottom: 10px;
}
.create-rout-root .routes-content .selected-assignee-container .selected-assignee-card .card-header {
  display: flex;
  align-items: center;
}
.create-rout-root .routes-content .selected-assignee-container .selected-assignee-card .card-header .checkbox {
  margin-right: 10px;
}
.create-rout-root .routes-content .selected-assignee-container .selected-assignee-card .card-header .avatar-box {
  margin-right: 10px;
  position: relative;
}
.create-rout-root .routes-content .selected-assignee-container .selected-assignee-card .card-header .assignee-name {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
}
.create-rout-root .routes-content .selected-assignee-container .selected-assignee-card .card-header .assignee-role {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
}
.create-rout-root .routes-content .selected-assignee-container .selected-assignee-card .card-header .assignee-status {
  background: orange;
  border: 1px solid #fff;
  height: 8px;
  width: 8px;
  border-radius: 8px;
  position: absolute;
  top: 3px;
  right: 0px;
  z-index: 1;
}
.create-rout-root .routes-content .selected-assignee-container .selected-assignee-card .card-header .assignee-status-active {
  background: #52C41A;
  border: 1px solid #fff;
  height: 8px;
  width: 8px;
  border-radius: 8px;
  position: absolute;
  top: 3px;
  right: 0px;
}
.create-rout-root .routes-content .selected-assignee-container .selected-assignee-card .card-header .assignee-status-icon {
  position: absolute;
  top: 0px;
  right: 0px;
}
.create-rout-root .routes-content .selected-assignee-container .selected-assignee-card .card-body {
  margin-top: 7px;
}
.create-rout-root .routes-content .selected-assignee-container .selected-assignee-card .card-body .label {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
}
.create-rout-root .routes-content .selected-assignee-container .selected-assignee-card .card-body .value {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 22px;
}
.create-rout-root .routes-content .selected-assignee-container .selected-assignee-card .card-body .user-specialty {
  line-height: 22px;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.create-rout-root .routes-content .selected-assignee-container .selected-assignee-card .card-footer {
  margin-top: 5px;
}
.create-rout-root .routes-content .selected-assignee-container .selected-assignee-card .card-footer .time-text {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
}
.create-rout-root .routes-content .selected-assignee-container .selected-assignee-card .card-footer .ant-dropdown-link {
  font-size: 14px;
}
.create-rout-root .link-btn {
  color: #15A9E1;
  font-size: 14px;
  margin-top: 15px;
  cursor: pointer;
}
.create-rout-root .routes-footer {
  position: fixed;
  right: 0px;
  left: 0px;
  padding: 24px;
  z-index: 0;
  background: #fff;
  bottom: 0px;
  border-top: 1px solid;
  border-color: #e6e6e6;
  margin-right: 20px;
}
.create-rout-root .assignee-select .ant-select-selection__choice {
  display: none;
  border: 1px solid;
}
.assignee-option {
  display: flex;
  padding: 12px;
}
.assignee-option .assignee-avatar {
  margin-right: 10px;
  position: relative;
}
.assignee-option .assignee-avatar .assignee-status {
  background: orange;
  border: 1px solid #fff;
  height: 8px;
  width: 8px;
  border-radius: 8px;
  position: absolute;
  top: 3px;
  right: 0px;
}
.assignee-option .assignee-avatar .assignee-status-active {
  background: #52C41A;
  border: 1px solid #fff;
  height: 8px;
  width: 8px;
  border-radius: 8px;
  position: absolute;
  top: 3px;
  right: 0px;
}
.assignee-option .assignee-avatar .assignee-status-icon {
  position: absolute;
  top: 0px;
  right: 0px;
}
.assignee-option .assignee-info .assignee-name {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
}
.assignee-option .assignee-info .assignee-role {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
}
.custom-schedule-modal .schedule-day-time-container {
  border: 1px solid #98DCF5;
  background-color: #E6F7FF;
  border-radius: 2px;
  padding: 12px 16px;
}
.custom-schedule-modal .schedule-day-time-container .day-label {
  font-size: 12px;
  font-weight: 600;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
}
.custom-schedule-modal .schedule-day-time-container .day-value {
  font-size: 14px;
  font-weight: 600;
  margin-top: 3px;
  line-height: 22px;
}
.custom-schedule-modal .input-container {
  margin-top: 20px;
  font-size: 14px;
}
.custom-schedule-modal .input-container .label {
  line-height: 22px;
  margin-bottom: 5px;
}
.custom-schedule-modal .input-container .required-star {
  color: #FF4D4F;
}
.assignee-dropdown-menu {
  max-height: 300px;
  overflow: auto;
}
.assignee-dropdown-menu .menu-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 10px;
}
.assignee-dropdown-menu .assignee-menu-item {
  border-bottom: 1px solid;
  border-color: rgba(0, 0, 0, 0.06);
}
.assignee-dropdown-menu .assignee-menu-item:hover {
  background-color: #F5FCFF;
}
.assignee-dropdown-menu .assignee-menu-item:hover .dropdown-check-icon {
  display: block;
}
.assignee-dropdown-menu .dropdown-check-icon {
  display: none;
  color: #15A9E1;
  font-size: 16px;
}
.assignee-dropdown-menu .assignee-menu-item-active {
  background-color: #F5FCFF;
}
.assignee-dropdown-menu .assignee-menu-item-active .dropdown-check-icon {
  display: block;
}
.ant-form-item-label > label::after {
  content: none;
}
.route-form .ant-form-item-label {
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 3px;
}
.route-form .custom-label {
  margin-bottom: 3px;
}
.route-form .required-star {
  color: #FF4D4F;
}
.route-form .optional-text {
  color: rgba(0, 0, 0, 0.45);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.text-gradient {
  background-image: -webkit-gradient(linear, 37.219838% 34.532506%, 36.425669% 93.178216%, from(#29cdff), to(#0a60ff), color-stop(0.37, #148eff));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.background-hover {
  transition: all 0.3s ease-out;
}
.background-hover:hover {
  background-color: #f9f9fc;
}
@font-face {
  font-family: 'Signatura Monoline Script';
  src: url(/static/media/SignaturaMonolineScript.1d03aea3.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}
body {
  height: 100%;
  background-color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
#dashboard #study-details-card-form .ant-radio-group .ant-radio-button-wrapper:not(:first-child)::before,
#routing-preferences #study-details-card-form .ant-radio-group .ant-radio-button-wrapper:not(:first-child)::before,
#routing-preferences-create-route #study-details-card-form .ant-radio-group .ant-radio-button-wrapper:not(:first-child)::before,
#dashboard #radiologist-performance-card-form .ant-radio-group .ant-radio-button-wrapper:not(:first-child)::before,
#routing-preferences #radiologist-performance-card-form .ant-radio-group .ant-radio-button-wrapper:not(:first-child)::before,
#routing-preferences-create-route #radiologist-performance-card-form .ant-radio-group .ant-radio-button-wrapper:not(:first-child)::before,
#dashboard #routes-filter-form .ant-radio-group .ant-radio-button-wrapper:not(:first-child)::before,
#routing-preferences #routes-filter-form .ant-radio-group .ant-radio-button-wrapper:not(:first-child)::before,
#routing-preferences-create-route #routes-filter-form .ant-radio-group .ant-radio-button-wrapper:not(:first-child)::before,
#dashboard #routes-create-form .ant-radio-group .ant-radio-button-wrapper:not(:first-child)::before,
#routing-preferences #routes-create-form .ant-radio-group .ant-radio-button-wrapper:not(:first-child)::before,
#routing-preferences-create-route #routes-create-form .ant-radio-group .ant-radio-button-wrapper:not(:first-child)::before {
  background-color: unset;
}
#dashboard #study-details-card-form .ant-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before,
#routing-preferences #study-details-card-form .ant-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before,
#routing-preferences-create-route #study-details-card-form .ant-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before,
#dashboard #radiologist-performance-card-form .ant-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before,
#routing-preferences #radiologist-performance-card-form .ant-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before,
#routing-preferences-create-route #radiologist-performance-card-form .ant-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before,
#dashboard #routes-filter-form .ant-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before,
#routing-preferences #routes-filter-form .ant-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before,
#routing-preferences-create-route #routes-filter-form .ant-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before,
#dashboard #routes-create-form .ant-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before,
#routing-preferences #routes-create-form .ant-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before,
#routing-preferences-create-route #routes-create-form .ant-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: unset !important;
}
.ant-radio-group .ant-radio-button-wrapper:not(:first-child)::before {
  background-color: unset;
}
.ant-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: unset !important;
}
.qc-table .ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0;
}
.qc-table .ant-table-thead {
  background-color: #00000005;
}
.qc-table .ant-table-expanded-row,
.qc-table .ant-table-expanded-row:hover {
  background: none;
}
.h5 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}
.table-expanded-row .ant-table-row-cell-break-word {
  padding: 8px;
}
.table-expanded-row .ant-table-tbody > tr > td {
  border-bottom: none;
}

