@import '../../../themes/vars';


.no-content-wrapper {
    padding: 20px;
    margin-bottom: 20px;
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 650px;
    text-align: center;

    .no-content-item {
        max-width: 340px;
    }

    .description {
        margin-top: 3em;
    }

    .button {
        margin-top: 1.5em;
    }

    .search-container {
        display: flex;
        flex-direction: row;
    }

}

.schedule-wrapper {
    display: flex;
    margin-top: 1rem;
}

.common-label {
    font-weight: 600;
    color: rgba(0, 0, 0, 1);
    line-height: 22px;
}

.ant-dropdown-menu {
    min-width: 150px;
}

.day-filter-menu {
    min-width: 150px;

    .ant-dropdown-submenu-item {
        min-width: 120px;
    }
}

.radiologist-card {
    display: flex;
    position: relative;
    margin-bottom: 15px;

    .rad-avatar {
        position: relative;
        margin-right: 15px;

        .ant-avatar {
            background: #3BBCED;
        }

        .badge {
            position: absolute;
            left: 23px;
            top: -3px;

            .ant-badge-status-dot {
                height: 8px;
                width: 8px;
                border: 1px solid white;
            }
        }
    }

    .rad-details {
        margin-right: 40px;
        width: 150px;

        .rad-name {
            font-weight: 600;
            color: rgba(0, 0, 0, 1);
            line-height: 22px;
            width: 140px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .rad-specialty {
            color: @text-color-secondary;
            margin-top: 7px;
        }
    }

    .hrs-text {
        position: absolute;
        right: 3px;
        color: @text-color-secondary;
    }
}

.time-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 12;
  

    .time-item {
        width: 100%;
        text-align: center;
        position: relative;
        color: @heading-color;
        // margin-bottom: 200px;
        height: 100%;
        // z-index:-1;

        .time-background-even {
            // border: 1px solid;
            height: 100%;
            position: absolute;
            top: 0px;
            bottom: -0%;
            z-index: 22;
            background-color:  rgba(0, 0, 0, 0.15);
            opacity: 0.3;
            width: 100%;
            left: 0px;
            right: 0px;
        }
        .time-background-odd {
            // border: 1px solid;
            height: 100%;
            position: absolute;
            top: 0px;
            bottom: -0%;
            z-index: 22;
            // background-color: rgba(191, 23, 23, 0.165);
            opacity: 0.3;
            width: 100%;
            left: 0px;
            right: 0px;
        }
    }
}


.schedule-card {
    margin-bottom: 20px;
    position: absolute;
    padding: 10px;
    z-index: 15;


    &-content {
        padding: 15px;
        display: flex;
    }

    &-left {
        .radiologist-avatar {
            background: @primary-color;
        }
        .radiographer-avatar {
            background: #722ED1;
        }
        .it_administrator-avatar {
            background: #2F54EB;

        }
    }

    &-right {
        margin-left: 15px;
    }

    &-name {
        font-weight: 600;
        color: rgba(0, 0, 0, 1);
        line-height: 22px;
    }

    &-specialty {
        margin-top: 5px;
        color: @text-color-secondary;
    }

    &-time {
        margin-top: 5px;
        display: flex;
        justify-content: space-between;
        color: @text-color-secondary;
    }
}

.common-schedule-header {
    width: 100%;

    &-radiologist {
        border-bottom: 1px solid #98DCF5;
        margin-top: 20px;
        position: relative;
        .ant-tag {            
            position: absolute;
            background: @primary-color;
            color: #fff;
            font-size: 16px;
            bottom: -13px;
            padding: 2px 10px;
            left: 20px;

        }
    }
    &-radiographer {
        border-bottom: 1px solid #722ED1;
        margin-top: 20px;
        position: relative;
        .ant-tag {            
            position: absolute;
            background: #722ED1;
            color: #fff;
            font-size: 16px;
            bottom: -13px;
            padding: 2px 10px;
            left: 20px;

        }
    }
    &-it_administrator {
        border-bottom: 1px solid #2F54EB;
        margin-top: 20px;
        position: relative;
        .ant-tag {            
            position: absolute;
            background: #2F54EB;
            color: #fff;
            font-size: 16px;
            bottom: -13px;
            padding: 2px 10px;
            left: 20px;

        }
    }
}
 .radiologist-background-border {
    border-bottom: 1px solid @primary-color;
    background-color: #F1FAFE;
    border-radius: 6px;
 }

 .radiographer-background-border {
    border-bottom: 1px solid #722ED1;
    border-radius: 6px;
    background-color: #F9F0FF;
 }

 .it_administrator-background-border {
    border-bottom: 1px solid #2F54EB;
    border-radius: 6px;
    background-color: #F0F5FF;
 }
 
.schedule-container {
    border: 1px solid red   ;
}
@white: #fff;@black: #000;@primary-color: #04a9e3;@primary-color-2: #04a9e3;@primary-color-accent: #1890ff;@border-color: #adafb2;@border-color-dark: #9b9b9b;@chart-table-border-color: #00000026;@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC',
  'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial,
  sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@font-size-base: 14px;@font-size-lg: @font-size-base + 2px;@font-size-sm: 14px;@alem-dark-blue: #0f2549;@layout-header-background: @alem-dark-blue;@layout-sider-background: @alem-dark-blue;@layout-trigger-background: #002140;@layout-trigger-color: #fff;@card-head-background: #f4f3f3;@heading-color: fade(#000, 85%);@heading-color-dark: fade(#fff, 100%);@icon-color: inherit;@icon-color-hover: fade(@black, 75%);@text-color: #666;@text-color-secondary: fade(@black, 45%);@text-color-inverse: @white;@text-color-dark: fade(@white, 85%);@text-color-secondary-dark: fade(@white, 65%);@text-selection-bg: @primary-color;@border-radius-base: 3px;@border-radius-sm: 2px;@shadow-color: rgba(0, 0, 0, 0.05);@shadow-1-down: 4px 4px 40px @shadow-color;@border-color-split: #f4f4f4;@border-color-base: #e5e5e5;@hover-color: #f9f9fc;@header-height: 60px;