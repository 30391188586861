.user-management-header {
  margin-bottom: 20px;

  .user-management-title {
    line-height: 24px;
    font-weight: 700;
    font-size: 20px;
    color: #404047;
    margin-bottom: 5px;
    // font-family: "Dancing Script" !important;
    // font-family: "Sacramento" !important;
    // font-family: "Signatura Monoline Script" ! important;
    
  }
}

// .user-management-index .ant-table table {
//   border-top: 0.5px solid #adafb2;
// }

.lateral-modal .ant-modal {
  position: absolute;
  min-height: 100%;
  width: auto;
  margin: 0 auto;
  top: 0;
  right: 0;
  bottom: 0;
}

.lateral-modal .ant-modal-content {
  min-height: 100vh;
  justify-content: space-between;
}

.lateral-modal .ant-modal-footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px 10px 10px;
  text-align: right;
  border-radius: 0 0 4px 4px;
}

.invite-table,
.user-table {
  width: 100%;

  .ant-table-pagination.ant-pagination {
    float: unset;
  }
  
  .ant-table-small {
    border: none;
  }
  .table-action-buttons {
    display: flex;
  }
}
@white: #fff;@black: #000;@primary-color: #04a9e3;@primary-color-2: #04a9e3;@primary-color-accent: #1890ff;@border-color: #adafb2;@border-color-dark: #9b9b9b;@chart-table-border-color: #00000026;@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC',
  'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial,
  sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@font-size-base: 14px;@font-size-lg: @font-size-base + 2px;@font-size-sm: 14px;@alem-dark-blue: #0f2549;@layout-header-background: @alem-dark-blue;@layout-sider-background: @alem-dark-blue;@layout-trigger-background: #002140;@layout-trigger-color: #fff;@card-head-background: #f4f3f3;@heading-color: fade(#000, 85%);@heading-color-dark: fade(#fff, 100%);@icon-color: inherit;@icon-color-hover: fade(@black, 75%);@text-color: #666;@text-color-secondary: fade(@black, 45%);@text-color-inverse: @white;@text-color-dark: fade(@white, 85%);@text-color-secondary-dark: fade(@white, 65%);@text-selection-bg: @primary-color;@border-radius-base: 3px;@border-radius-sm: 2px;@shadow-color: rgba(0, 0, 0, 0.05);@shadow-1-down: 4px 4px 40px @shadow-color;@border-color-split: #f4f4f4;@border-color-base: #e5e5e5;@hover-color: #f9f9fc;@header-height: 60px;