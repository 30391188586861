@import './vars.less';

@font-face {
  //font-family: NotoSans-Regular;
  //src: url(../assets/fonts/NotoSans-Regular.ttf) format('opentype');
  font-family: 'Signatura Monoline Script';
  src: url('../assets/fonts/SignaturaMonolineScript.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap&.css');
@import url('https://fonts.googleapis.com/css2?family=Sacramento&display=swap&.css');
@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&family=Sacramento&display=swap&.css');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Kaushan+Script&family=Sacramento&display=swap&.css');

body {
  height: 100%;
  // overflow-y: hidden;
  background-color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC',
    'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

#dashboard,
#routing-preferences,
#routing-preferences-create-route {
  #study-details-card-form,
  #radiologist-performance-card-form,
  #routes-filter-form,
  #routes-create-form {
    .ant-radio-group {
      .ant-radio-button-wrapper:not(:first-child)::before {
        background-color: unset;
      }

      .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
        background-color: unset !important;
      }
    }
  }
}

.ant-radio-group {
  .ant-radio-button-wrapper:not(:first-child)::before {
    background-color: unset;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: unset !important;
  }
}

//::-webkit-scrollbar-thumb {
//  background-color: #e6e6e6;
//}
//
//::-webkit-scrollbar {
//  width: 8px;
//  height: 8px;
//}
//
//:global {
//  .page-cta-wrapper {
//    position: absolute;
//    top: 24px;
//    right: 24px;
//  }
//
//  .ant-btn-primary {
//    background-color: #04a9e3;
//    border-color: #04a9e3;
//  }
//
//  .ant-btn-primary:hover {
//    background-color: @primary-color-accent;
//    border-color: @primary-color-accent;
//  }
//
//  .ant-breadcrumb-link {
//    .anticon + span {
//      margin-left: 4px;
//    }
//  }
//
//  .ant-table {
//    .ant-table-thead > tr > th {
//      text-align: center;
//    }
//
//    .ant-table-tbody > tr > td {
//      text-align: center;
//    }
//
//    &.ant-table-small {
//      .ant-table-thead > tr > th {
//        background: #f7f7f7;
//      }
//
//      .ant-table-body > table {
//        padding: 0;
//      }
//    }
//  }
//
//  .ant-table-pagination {
//    float: none !important;
//    display: table;
//    margin: 16px auto !important;
//  }
//
//  .ant-popover-inner {
//    border: none;
//    border-radius: 0;
//    box-shadow: 0 0 20px rgba(100, 100, 100, 0.2);
//  }
//
//  .ant-form-item-control {
//    vertical-align: middle;
//  }
//
//  .ant-modal-mask {
//    background-color: rgba(55, 55, 55, 0.2);
//  }
//
//  .ant-modal-content {
//    box-shadow: none;
//  }
//
//  .ant-select-dropdown-menu-item {
//    padding: 12px 16px !important;
//  }
//
//  .margin-right {
//    margin-right: 16px;
//  }
//
//  a:focus {
//    text-decoration: none;
//  }
//}
//
//@media (min-width: 1600px) {
//  :global {
//    .ant-col-xl-48 {
//      width: 20%;
//    }
//
//    .ant-col-xl-96 {
//      width: 40%;
//    }
//  }
//}
//@media (max-width: 767px) {
//  :global {
//    .ant-pagination-item,
//    .ant-pagination-next,
//    .ant-pagination-options,
//    .ant-pagination-prev {
//      margin-bottom: 8px;
//    }
//
//    .ant-card {
//      .ant-card-head {
//        padding: 0 12px;
//      }
//
//      .ant-card-body {
//        padding: 12px;
//      }
//    }
//  }
//}

.qc-table {
  .ant-table-small > .ant-table-content > .ant-table-body {
    margin: 0;
  }
  .ant-table-thead {
    background-color: #00000005;
  }
  
  .ant-table-expanded-row, .ant-table-expanded-row:hover {
    background: none;
  }
}

.h5 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

.table-expanded-row {
  .ant-table-row-cell-break-word {
    padding: 8px;
  }

  .ant-table-tbody > tr > td {
    border-bottom: none;
  }

}
@white: #fff;@black: #000;@primary-color: #04a9e3;@primary-color-2: #04a9e3;@primary-color-accent: #1890ff;@border-color: #adafb2;@border-color-dark: #9b9b9b;@chart-table-border-color: #00000026;@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC',
  'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial,
  sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@font-size-base: 14px;@font-size-lg: @font-size-base + 2px;@font-size-sm: 14px;@alem-dark-blue: #0f2549;@layout-header-background: @alem-dark-blue;@layout-sider-background: @alem-dark-blue;@layout-trigger-background: #002140;@layout-trigger-color: #fff;@card-head-background: #f4f3f3;@heading-color: fade(#000, 85%);@heading-color-dark: fade(#fff, 100%);@icon-color: inherit;@icon-color-hover: fade(@black, 75%);@text-color: #666;@text-color-secondary: fade(@black, 45%);@text-color-inverse: @white;@text-color-dark: fade(@white, 85%);@text-color-secondary-dark: fade(@white, 65%);@text-selection-bg: @primary-color;@border-radius-base: 3px;@border-radius-sm: 2px;@shadow-color: rgba(0, 0, 0, 0.05);@shadow-1-down: 4px 4px 40px @shadow-color;@border-color-split: #f4f4f4;@border-color-base: #e5e5e5;@hover-color: #f9f9fc;@header-height: 60px;