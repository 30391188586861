@import '../../themes/vars.less';

.sider {
  box-shadow: fade(@primary-color, 10%) 0 0 28px 0;
  z-index: 10;
  :global {
    .ant-layout-sider-children {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: calc(100% - @header-height);
    }
  }
}

.header {
  z-index: 1;
  height: @header-height;
  display: flex;
}

.menuContainer {
  overflow-x: hidden;
  flex: 1;
  margin-top: 60px;
  // padding: 42px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  :global .ant-menu-dark {
    .ant-menu-item {
      &.ant-menu-item-selected,
      &.ant-menu-item-active {
        .anticon,
        a span {
          color: #29cdff;
        }
      }

      a {
        color: #fff !important;
        & > * {
          font-size: 14px;
        }
      }
    }

    .ant-menu-submenu-title > * {
      color: #fff !important;
      font-size: 14px;
    }
    > .ant-menu-submenu-selected,
    .ant-menu-item-selected {
      background-color: transparent;
    }
    .ant-menu-submenu-open {
      > * {
        box-shadow: none !important;
        background-color: transparent;
      }
    }
  }
}

@keyframes fadeLeftIn {
  0% {
    transform: translateX(5px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@white: #fff;@black: #000;@primary-color: #04a9e3;@primary-color-2: #04a9e3;@primary-color-accent: #1890ff;@border-color: #adafb2;@border-color-dark: #9b9b9b;@chart-table-border-color: #00000026;@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC',
  'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial,
  sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@font-size-base: 14px;@font-size-lg: @font-size-base + 2px;@font-size-sm: 14px;@alem-dark-blue: #0f2549;@layout-header-background: @alem-dark-blue;@layout-sider-background: @alem-dark-blue;@layout-trigger-background: #002140;@layout-trigger-color: #fff;@card-head-background: #f4f3f3;@heading-color: fade(#000, 85%);@heading-color-dark: fade(#fff, 100%);@icon-color: inherit;@icon-color-hover: fade(@black, 75%);@text-color: #666;@text-color-secondary: fade(@black, 45%);@text-color-inverse: @white;@text-color-dark: fade(@white, 85%);@text-color-secondary-dark: fade(@white, 65%);@text-selection-bg: @primary-color;@border-radius-base: 3px;@border-radius-sm: 2px;@shadow-color: rgba(0, 0, 0, 0.05);@shadow-1-down: 4px 4px 40px @shadow-color;@border-color-split: #f4f4f4;@border-color-base: #e5e5e5;@hover-color: #f9f9fc;@header-height: 60px;