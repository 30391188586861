.group-facilities>p {
  font-weight: bold;
  margin: -12px 0 17px 0;
}

.ant-radio-group.admin-access {
  width: 100%;

  .ant-radio-button-wrapper {
    width: 50%;
    text-align: center;
  }
}

.ant-modal-footer {
  background: #fff;
}

.user-form__actions {
  .ant-row.ant-form-item {
    margin: 0;
    text-align: left;

    .ant-form-item-label {
      line-height: 26px;
    }

    .ant-form-item-control {
      line-height: 30px;
    }
  }
}

.lateral-modal {

  .facility-container {
    height: 300px;
    overflow: auto;

  }

  .signature-card {
    .empty-sign-text {
      color: rgba(0, 0, 0, 0.45);
      font-size: 14px;
      line-height: 22px;
    }

    .ant-empty-image {
      height: 100px;

      svg {
        width: 180px;
        height: 100px;
      }
    }

    .ant-empty-normal {
      margin: 0px;
    }
    .signature-delete-btn {
      color: #FF4D4F;
    }
  }

  .main-preview-card {
    .ant-card-body {
      display: flex;
      justify-content: center;
    }
  }

  ::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    width: 4px;
    margin-top: -5px;
    margin-bottom: -5px;
    background: #ccc;
    border: 2px;
    // background-clip: padding-box;
    border-radius: 20px;
    // margin-right: -20px;
  }
  
}
@white: #fff;@black: #000;@primary-color: #04a9e3;@primary-color-2: #04a9e3;@primary-color-accent: #1890ff;@border-color: #adafb2;@border-color-dark: #9b9b9b;@chart-table-border-color: #00000026;@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC',
  'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial,
  sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@font-size-base: 14px;@font-size-lg: @font-size-base + 2px;@font-size-sm: 14px;@alem-dark-blue: #0f2549;@layout-header-background: @alem-dark-blue;@layout-sider-background: @alem-dark-blue;@layout-trigger-background: #002140;@layout-trigger-color: #fff;@card-head-background: #f4f3f3;@heading-color: fade(#000, 85%);@heading-color-dark: fade(#fff, 100%);@icon-color: inherit;@icon-color-hover: fade(@black, 75%);@text-color: #666;@text-color-secondary: fade(@black, 45%);@text-color-inverse: @white;@text-color-dark: fade(@white, 85%);@text-color-secondary-dark: fade(@white, 65%);@text-selection-bg: @primary-color;@border-radius-base: 3px;@border-radius-sm: 2px;@shadow-color: rgba(0, 0, 0, 0.05);@shadow-1-down: 4px 4px 40px @shadow-color;@border-color-split: #f4f4f4;@border-color-base: #e5e5e5;@hover-color: #f9f9fc;@header-height: 60px;