.custom-dropdown-checkbox-container {
    // display: flex;

    .custom-dropdown {
        padding: 5px 10px;
        border: 1px solid #d9d9d9;
        border-radius: 3px;
        width: 100%;

        .dropdown-text {
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .dropdown-text-placeholder {
            font-size: 14px;
        }
    }

    .dropdown-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .dropdown-label {
        text-align: start;
        line-height: 22px;
        font-weight: 600;
        margin-bottom: 5px;
        color: rgba(0, 0, 0, 0.45);
    }

}

.custom-checkbox-container {
    // background: #ffffff;
    padding: 8px;

    // box-shadow: -1px 2px 5px 2px rgba(0, 0, 0, 0.2);
    .search-box {
        margin: 10px 0px;
    }
    .field-checkbox-container {
        max-height: 22vh;
        overflow: auto;
    }
    .checkbox-inner-container {
        // max-height: 25vh;
        overflow: auto;
        .ant-checkbox-group {
            display: flex;
            flex-direction: column;

            .ant-checkbox-group-item {
                padding-bottom: 8px;
            }
        }
    }

    .dropdown-buttons {
        display: flex;
        justify-content: space-between;
        padding-top: 10px;
    }

    .ant-menu-vertical {
        border: none;
    }
    ::-webkit-scrollbar {
        width: 4px;
        background-color: transparent;
      }
    
      ::-webkit-scrollbar-thumb {
        width: 4px;
        margin-top: -5px;
        margin-bottom: -5px;
        background: #ccc;
        border: 2px;
        // background-clip: padding-box;
        border-radius: 20px;
        // margin-right: -20px;
    }
}
@white: #fff;@black: #000;@primary-color: #04a9e3;@primary-color-2: #04a9e3;@primary-color-accent: #1890ff;@border-color: #adafb2;@border-color-dark: #9b9b9b;@chart-table-border-color: #00000026;@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC',
  'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial,
  sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@font-size-base: 14px;@font-size-lg: @font-size-base + 2px;@font-size-sm: 14px;@alem-dark-blue: #0f2549;@layout-header-background: @alem-dark-blue;@layout-sider-background: @alem-dark-blue;@layout-trigger-background: #002140;@layout-trigger-color: #fff;@card-head-background: #f4f3f3;@heading-color: fade(#000, 85%);@heading-color-dark: fade(#fff, 100%);@icon-color: inherit;@icon-color-hover: fade(@black, 75%);@text-color: #666;@text-color-secondary: fade(@black, 45%);@text-color-inverse: @white;@text-color-dark: fade(@white, 85%);@text-color-secondary-dark: fade(@white, 65%);@text-selection-bg: @primary-color;@border-radius-base: 3px;@border-radius-sm: 2px;@shadow-color: rgba(0, 0, 0, 0.05);@shadow-1-down: 4px 4px 40px @shadow-color;@border-color-split: #f4f4f4;@border-color-base: #e5e5e5;@hover-color: #f9f9fc;@header-height: 60px;