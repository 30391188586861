@import '../themes/vars.less';

.backTop {
  right: 50px;

  :global {
    .ant-back-top-content {
      opacity: 0.3;
      transition: all 0.3s;
      box-shadow: 0 0 15px 1px rgba(69, 65, 78, 0.1);

      &:hover {
        opacity: 1;
      }
    }
  }
}

.content {
  padding: 24px;
  min-height: 100vh;
}
.white-background {
  background: #FFFFFF;
}

// .container {
//   height: 100vh;
//   flex: 1;
//   width: ~'calc(100% - 256px)';
//   overflow-y: scroll;
//   overflow-x: hidden;
// }

// .footer {
//   background: #fff;
//   margin-top: 0;
//   margin-bottom: 0;
//   padding-top: 24px;
//   padding-bottom: 24px;
//   min-height: 72px;
// }

@media (max-width: 767px) {
  .content {
    padding: 12px;
  }

  .backTop {
    right: 20px;
    bottom: 20px;
  }

  .container {
    height: 100vh;
    flex: 1;
    width: 100%;
  }
}

.table-dashboard-study-details tr,
.table-dashboard-study-details td {
  border: 0px !important;
}
.table-with-total table,
.table-with-total tbody tr:last-child,
.table-with-total tbody tr:last-child td {
  border: 0px !important;
}

.table-dashboard-radiologist-performance td {
  padding: 10px 20px !important;
  border-color: #adafb2 !important;
}

.table-dashboard-radiologist-performance .ant-table-content td {
  border-top-color: transparent !important;
}

.table-dashboard-radiologist-performance tr {
  border: 0px !important;
}

.table-dashboard-radiologist-performance table,
.table-dashboard table,
.table-dashboard tr {
  border-left: 0px !important;
  border-right: 0px !important;
}

.table-dashboard-radiologist-performance--footer th {
  border-top-color: transparent !important;
}

@white: #fff;@black: #000;@primary-color: #04a9e3;@primary-color-2: #04a9e3;@primary-color-accent: #1890ff;@border-color: #adafb2;@border-color-dark: #9b9b9b;@chart-table-border-color: #00000026;@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC',
  'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial,
  sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@font-size-base: 14px;@font-size-lg: @font-size-base + 2px;@font-size-sm: 14px;@alem-dark-blue: #0f2549;@layout-header-background: @alem-dark-blue;@layout-sider-background: @alem-dark-blue;@layout-trigger-background: #002140;@layout-trigger-color: #fff;@card-head-background: #f4f3f3;@heading-color: fade(#000, 85%);@heading-color-dark: fade(#fff, 100%);@icon-color: inherit;@icon-color-hover: fade(@black, 75%);@text-color: #666;@text-color-secondary: fade(@black, 45%);@text-color-inverse: @white;@text-color-dark: fade(@white, 85%);@text-color-secondary-dark: fade(@white, 65%);@text-selection-bg: @primary-color;@border-radius-base: 3px;@border-radius-sm: 2px;@shadow-color: rgba(0, 0, 0, 0.05);@shadow-1-down: 4px 4px 40px @shadow-color;@border-color-split: #f4f4f4;@border-color-base: #e5e5e5;@hover-color: #f9f9fc;@header-height: 60px;