@alem-health-color: #5FA8DD;
@max-healthcare-color:  #62B6A9;
@apollo-hospitals-color: #367B9B;

.pulse(@name, @color) {
  @keyframes @name {
    100%{
      width: 42px;
      height: 42px;
      box-shadow: 0 0 10px 3px @color;
    }
  }
}

.pulse(alem-health-pulse, @alem-health-color);
.pulse(max-healthcare-pulse, @max-healthcare-color);
.pulse(apollo-hospitals-pulse, @apollo-hospitals-color);

.circle {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: inline-block
}

.alem-health-circle {
  &:extend(.circle);
  background-color: @alem-health-color;
  &:hover {
    // animation: alem-health-pulse 0.5s 1 both;
  }
}

.max-healthcare-circle {
  &:extend(.circle);
  background-color: @max-healthcare-color;
  &:hover {
    //animation: .pulse(max-healthcare-pulse, @max-healthcare-color)
  }
}

.apollo-hospitals-circle {
  &:extend(.circle);
  background-color: @apollo-hospitals-color;
  &:hover {
    //animation: .pulse(apollo-hospitals-pulse, @apollo-hospitals-color)
  }
}

.provider-name {
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 0;
}

.provider-description {
  color: #AFAEAE;
  padding: 10px 0 10px 0;
}

.request-service >p {
  padding: 0 0 20px 20px;
}

.modality-row {
  padding-bottom: 15px;
}

.modality-group {
  width: 100%;
}

.request-quotation {
  padding: 40px 0 30px 0;
}

.success-message {
  text-align: center;
  h3 {
    margin-top: 64px;
    font-weight: bold;
    font-size: 16px;
  }
  h4 {
    font-size: 16px;
    margin-bottom: 32px;
  }
}

@white: #fff;@black: #000;@primary-color: #04a9e3;@primary-color-2: #04a9e3;@primary-color-accent: #1890ff;@border-color: #adafb2;@border-color-dark: #9b9b9b;@chart-table-border-color: #00000026;@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC',
  'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial,
  sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@font-size-base: 14px;@font-size-lg: @font-size-base + 2px;@font-size-sm: 14px;@alem-dark-blue: #0f2549;@layout-header-background: @alem-dark-blue;@layout-sider-background: @alem-dark-blue;@layout-trigger-background: #002140;@layout-trigger-color: #fff;@card-head-background: #f4f3f3;@heading-color: fade(#000, 85%);@heading-color-dark: fade(#fff, 100%);@icon-color: inherit;@icon-color-hover: fade(@black, 75%);@text-color: #666;@text-color-secondary: fade(@black, 45%);@text-color-inverse: @white;@text-color-dark: fade(@white, 85%);@text-color-secondary-dark: fade(@white, 65%);@text-selection-bg: @primary-color;@border-radius-base: 3px;@border-radius-sm: 2px;@shadow-color: rgba(0, 0, 0, 0.05);@shadow-1-down: 4px 4px 40px @shadow-color;@border-color-split: #f4f4f4;@border-color-base: #e5e5e5;@hover-color: #f9f9fc;@header-height: 60px;