@import './../../themes/vars';

.onboarding-item {
  &--done {
    border: 0px;
    background-color: #e1f4d8;
    margin-bottom: 10px;
    padding: 15px 25px;
    color: #000;
    border-radius: 3px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }

  &--active {
    border: 0px;
    background-color: #297bb8;
    margin-bottom: 10px;
    padding: 15px 25px;
    color: #fff;
    border-radius: 3px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }

  &--waiting {
    border: 0px;
    background-color: #f3fbfd;
    margin-bottom: 10px;
    padding: 15px 25px;
    color: #000;
    border-radius: 3px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }
}

.onboarding-action {
  &-subtitle {
    color: #979797;
    margin: 0px 0px 20px !important;
    display: inline-block !important;
  }

  &-title {
    margin-bottom: 0px !important;
  }

  &-add-another {
    color: #1890ff;
    border-color: #1890ff;
    margin-top: 20px;
  }

  &-buttons {
    margin-top: 20px;
    text-align: right;
  }
}

.onboarding-action-buttons .ant-btn-line {
  border-color: #04a9e3;
  color: #04a9e3;
}

@white: #fff;@black: #000;@primary-color: #04a9e3;@primary-color-2: #04a9e3;@primary-color-accent: #1890ff;@border-color: #adafb2;@border-color-dark: #9b9b9b;@chart-table-border-color: #00000026;@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC',
  'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial,
  sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@font-size-base: 14px;@font-size-lg: @font-size-base + 2px;@font-size-sm: 14px;@alem-dark-blue: #0f2549;@layout-header-background: @alem-dark-blue;@layout-sider-background: @alem-dark-blue;@layout-trigger-background: #002140;@layout-trigger-color: #fff;@card-head-background: #f4f3f3;@heading-color: fade(#000, 85%);@heading-color-dark: fade(#fff, 100%);@icon-color: inherit;@icon-color-hover: fade(@black, 75%);@text-color: #666;@text-color-secondary: fade(@black, 45%);@text-color-inverse: @white;@text-color-dark: fade(@white, 85%);@text-color-secondary-dark: fade(@white, 65%);@text-selection-bg: @primary-color;@border-radius-base: 3px;@border-radius-sm: 2px;@shadow-color: rgba(0, 0, 0, 0.05);@shadow-1-down: 4px 4px 40px @shadow-color;@border-color-split: #f4f4f4;@border-color-base: #e5e5e5;@hover-color: #f9f9fc;@header-height: 60px;