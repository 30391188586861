.create-rout-root {
    .routes-header-wrapper {
        min-height: 240px;
        .bread {
            margin-bottom: 5px;
            font-size: 14px
        }
        .routes-header { 
            background-color: #FFFFFF;
            position: absolute;
            left: -24px;
            right: -24px;
            top: -24px;
            padding: 24px;
            border-bottom: 1px solid;
            border-color: rgba(0, 0, 0, 0.15);
            .steps-container {
                color: rgba(0, 0, 0, 0.15);

                .steps-card {
                    margin: 24px 0px 5px 0px;
                }
                
            }
        }
    }
    

    .routes-content {
        display: flex;
        justify-content: center;
        margin-top: 30px;

        .routes-card {
            background: #fff;
            height: 100%;
            width: 600px;
            // width: 50px;
        }

        .facility-card {
            width: 600px;

            .checkbox-container {
                margin-top: 10px;
                font-size: 14px;
                cursor: pointer;
                display: flex;
                align-items: center;
            }

            .facility-selection-container {
                height: 240px;
                overflow: auto;
                margin-top: 15px;

                .facility-element {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 12px;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.06);

                    &:hover {
                        background: #F5FCFF;

                        .facility-country {
                            font-weight: 600;
                        }
                    }

                    .facility-name {
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 22px;
                        margin-bottom: 4px;
                    }

                    .facility-country {
                        font-size: 14px;
                        line-height: 22px;
                        color: rgba(0, 0, 0, 0.45);
                    }
                }

                .selected-facility {
                    background: #F5FCFF;

                    .facility-country {
                        font-weight: 600;
                    }
                }
            }

            ::-webkit-scrollbar {
                width: 6px;
                background-color: transparent;
            }

            ::-webkit-scrollbar-thumb {
                width: 6px;
                margin-top: -5px;
                margin-bottom: -5px;
                background: #ccc;
                border: 2px;
                border-radius: 20px;
            }
        }

        .day-container {
            margin-top: 15px;

            .title {
                font-size: 14px;
                font-weight: 600;
            }

            .day-week-container {
                margin-top: 10px;
                display: flex;

                .day-week-element {
                    padding: 2px 8px;
                    background: #faf9f9;
                    margin-right: 10px;
                    font-size: 14px;
                    border-radius: 2px;
                    cursor: pointer;
                }

                .active-day {
                    background: #15A9E1;
                    color: #FFFFFF;
                }
            }

            .days-container {
                margin-top: 10px;
                display: flex;

                .day-week-element {
                    padding: 2px 8px;
                    background: #faf9f9;
                    margin-right: 10px;
                    font-size: 14px;
                    border-radius: 2px;
                    cursor: pointer;
                }

                .active-day {
                    background: #15A9E1;
                    color: #FFFFFF;
                }
            }

            .time-picker {
                margin-top: 5px;
            }

            .switch-container {
                margin-top: 10px;
                display: flex;
                align-items: center;

                .switch {
                    margin-right: 7px;
                }
            }

            .time-picker-container {
                display: flex;

                .time-picker {
                    width: 100%;
                }
            }
            .time-zone-utc {
                color: #00000073;
            }
        }

        .timezone-box {
            background: #E6F7FF;
            border-radius: 6px;
            padding: 8px 10px;
            margin: 8px 0px;
            .utc-location-text {
                color: #00000073;
                font-size: 12px;
                line-height: 19px;
            }
        }

        .ant-card-bordered {
            border: 1px solid #e6e6e6;
            border-radius: 8px;
        }

        .error-message {
            text-align: left;
            margin-top: 10px;
            font-size: 14px;
        }

        .assignee-select {
            width: 100%;
            margin-top: 8px;
        }

        .assignee-dropdown .ant-select-dropdown-menu-item {
            display: flex;
            align-items: center;
        }

        .route-detail-card {
            .label {
                font-size: 14px;
                line-height: 22px;
                color: rgba(0, 0, 0, 0.85);
                margin-bottom: 8px;
            }

            .required-star {
                color: #FF4D4F;
            }

            .optional-text {
                color: rgba(0, 0, 0, 0.45);
            }
        }

        .selected-assignee-container {
            margin-top: 20px;

            .selected-assignee-card {
                border: 1px dashed;
                border-color: rgba(0, 0, 0, 0.15);
                border-radius: 2px;
                padding: 12px;
                margin-bottom: 10px;

                .card-header {
                    display: flex;
                    align-items: center;

                    .checkbox {
                        margin-right: 10px;
                    }

                    .avatar-box {
                        margin-right: 10px;
                        position: relative;
                    }

                    .assignee-name {
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 22px;
                        color: rgba(0, 0, 0, 0.85);
                    }

                    .assignee-role {
                        font-size: 14px;
                        color: rgba(0, 0, 0, 0.45);
                    }

                    .assignee-status {
                        background: orange;
                        border: 1px solid #fff;
                        height: 8px;
                        width: 8px;
                        border-radius: 8px;
                        position: absolute;
                        top: 3px;
                        right: 0px;
                        z-index: 1;
                    }

                    .assignee-status-active {
                        background: #52C41A;
                        border: 1px solid #fff;
                        height: 8px;
                        width: 8px;
                        border-radius: 8px;
                        position: absolute;
                        top: 3px;
                        right: 0px;
                    }

                    .assignee-status-icon {
                        position: absolute;
                        top: 0px;
                        right: 0px;
                    }
                }

                .card-body {
                    margin-top: 7px;

                    .label {
                        font-size: 14px;
                        color: rgba(0, 0, 0, 0.45);
                    }

                    .value {
                        font-size: 14px;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 22px;
                    }

                    .user-specialty {
                        line-height: 22px;
                        font-size: 14px;
                        font-weight: 600;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }

                .card-footer {
                    margin-top: 5px;

                    .time-text {
                        font-size: 14px;
                        color: rgba(0, 0, 0, 0.45);
                    }

                    .ant-dropdown-link {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .link-btn {
        color: #15A9E1;
        font-size: 14px;
        margin-top: 15px;
        cursor: pointer;
    }

    .routes-footer {
        position: fixed;
        right: 0px;
        left: 0px;
        padding: 24px;
        z-index: 0;
        background: #fff;
        bottom: 0px;
        border-top: 1px solid;
        border-color: #e6e6e6;
        margin-right: 20px;
    }

    .assignee-select {
        .ant-select-selection__choice {
            display: none;
            border: 1px solid;
        }
    }
}

.assignee-option {
    display: flex;
    padding: 12px;

    .assignee-avatar {
        margin-right: 10px;
        position: relative;

        .assignee-status {
            background: orange;
            border: 1px solid #fff;
            height: 8px;
            width: 8px;
            border-radius: 8px;
            position: absolute;
            top: 3px;
            right: 0px;
        }

        .assignee-status-active {
            background: #52C41A;
            border: 1px solid #fff;
            height: 8px;
            width: 8px;
            border-radius: 8px;
            position: absolute;
            top: 3px;
            right: 0px;
        }

        .assignee-status-icon {
            position: absolute;
            top: 0px;
            right: 0px;
        }
    }

    .assignee-info {
        .assignee-name {
            font-size: 14px;
            font-weight: 600;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.85);
        }

        .assignee-role {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.45);
        }
    }
}

.custom-schedule-modal {

    .schedule-day-time-container {
        border: 1px solid #98DCF5;
        background-color: #E6F7FF;
        border-radius: 2px;
        padding: 12px 16px;

        .day-label {
            font-size: 12px;
            font-weight: 600;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.45);
        }

        .day-value {
            font-size: 14px;
            font-weight: 600;
            margin-top: 3px;
            line-height: 22px;
        }
    }

    .input-container {
        margin-top: 20px;
        font-size: 14px;

        .label {
            line-height: 22px;
            margin-bottom: 5px;
        }

        .required-star {
            color: #FF4D4F;
        }
    }
}

.assignee-dropdown-menu {
    max-height: 300px;
    overflow: auto;

    .menu-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 10px;
    }

    .assignee-menu-item {
        border-bottom: 1px solid;
        border-color: rgba(0, 0, 0, 0.06);
    }

    .assignee-menu-item:hover {
        background-color: #F5FCFF;

        .dropdown-check-icon {
            display: block;
        }
    }

    .dropdown-check-icon {
        display: none;
        color: #15A9E1;
        font-size: 16px;
    }

    .assignee-menu-item-active {
        background-color: #F5FCFF;

        .dropdown-check-icon {
            display: block;
        }
    }

}

.ant-form-item-label>label::after {
    content: none;
}

.route-form {
    .ant-form-item-label {
        // font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.85);
        margin-bottom: 3px;
    }

    .custom-label {
        margin-bottom: 3px;
    }

    .required-star {
        color: #FF4D4F;
    }

    .optional-text {
        color: rgba(0, 0, 0, 0.45);
    }
}
@white: #fff;@black: #000;@primary-color: #04a9e3;@primary-color-2: #04a9e3;@primary-color-accent: #1890ff;@border-color: #adafb2;@border-color-dark: #9b9b9b;@chart-table-border-color: #00000026;@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC',
  'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial,
  sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';@font-size-base: 14px;@font-size-lg: @font-size-base + 2px;@font-size-sm: 14px;@alem-dark-blue: #0f2549;@layout-header-background: @alem-dark-blue;@layout-sider-background: @alem-dark-blue;@layout-trigger-background: #002140;@layout-trigger-color: #fff;@card-head-background: #f4f3f3;@heading-color: fade(#000, 85%);@heading-color-dark: fade(#fff, 100%);@icon-color: inherit;@icon-color-hover: fade(@black, 75%);@text-color: #666;@text-color-secondary: fade(@black, 45%);@text-color-inverse: @white;@text-color-dark: fade(@white, 85%);@text-color-secondary-dark: fade(@white, 65%);@text-selection-bg: @primary-color;@border-radius-base: 3px;@border-radius-sm: 2px;@shadow-color: rgba(0, 0, 0, 0.05);@shadow-1-down: 4px 4px 40px @shadow-color;@border-color-split: #f4f4f4;@border-color-base: #e5e5e5;@hover-color: #f9f9fc;@header-height: 60px;